<template>
  <div v-click-outside="() => closeDropdown()" class="main">
    <div
      ref="container"
      @click="focusInputElement"
      class="form-field-large"
      :style="fieldStyle"
    >
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        style="display: block"
        v-if="iconLeft"
        :src="require(`@/assets/images/` + iconLeft)"
        alt=""
      />
      <div
        :class="{ required: fieldRequired }"
        style="display: flex; width: 100%; flex-direction: column"
      >
        <label
          v-if="placeholder && !loading"
          ref="label"
          :style="labelStyle"
          class="content"
          >{{ placeholder }}</label
        >
        <div
          class="loader-bar"
          style="position: absolute; left: 68px; bottom: 32px"
          v-if="loading"
        ></div>
        <input
          v-show="!loading"
          @focusin="focusInputElementPlaceholder"
          @keyup.esc="closeDropdown"
          @input="fieldValue = $event.target.value"
          :disabled="fieldDisabled"
          ref="input"
          class="input-field content"
          v-model="fieldValue"
        />
      </div>
      <div
        v-if="this.fieldValue"
        @click="removeFieldContent"
        class="remove-button"
      >
        <img
          :src="require(`@/assets/images/close.svg`)"
          width="24"
          height="24"
        />
      </div>
    </div>
    <div v-if="items && isOpen" id="items-dropdown" class="items">
      <div class="item-wrapper" ref="scrollableArea" @scroll="handleScroll">
        <div :key="resultsUpdater">
          <div v-if="fieldValue && items.length > 0">
            <div
              v-for="(item, index) in items"
              @click="chooseResultItem(item)"
              :key="item._id"
              :id="'client-list-search-item-' + index"
              class="item"
            >
              <p>
                {{ item.customerName }}
                <span v-if="item.organization" style="padding-left: 4px"
                  >{{ "- " }}{{ item.organization }}</span
                >
                <span style="padding-left: 4px" v-else
                  >{{ "- " }}{{ $t("PrivatePerson.Label").toLowerCase() }}</span
                >
                <span style="padding-left: 4px" v-if="item.contactIsPartner"
                  >( Partner )</span
                >
              </p>
            </div>
          </div>
          <div v-else-if="fieldValue && items.length === 0">
            <p class="no-results">
              {{ $t("NoResultsFound") }} "{{ fieldValue }}"
            </p>
          </div>
          <div v-else>
            <div
              v-for="(item, index) in items"
              :id="'client-list-search-item-' + index"
              @click="chooseResultItem(item)"
              :key="index"
              class="item"
            >
              <p>
                {{ item.customerName }}
                <span v-if="item.organization" style="padding-left: 4px"
                  >{{ "- " }}{{ item.organization }}</span
                >
                <span style="padding-left: 4px" v-else
                  >{{ "- " }}{{ $t("PrivatePerson.Label").toLowerCase() }}</span
                >
                <span style="padding-left: 4px" v-if="item.contactIsPartner"
                  >( Partner )</span
                >
              </p>
            </div>
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          v-if="isFetching && hasMoreItems"
        >
          <div class="loader"></div>
        </div>
      </div>
      <div
        v-if="!addNewDisabled"
        tabindex="0"
        @click="dropdownAddNewButton"
        style="display: flex; gap: 8px; justify-content: center"
        class="item add-new"
      >
        <img
          :src="require(`@/assets/images/action-add_16_16.svg`)"
          width="22"
          height="22"
        />
        <p>{{ $t("AddNew") }}</p>
      </div>
    </div>
    <div
      class="flex-row content error-message"
      v-if="fieldError"
      style="margin: 12px 12px 0 0"
    >
      {{
        fieldErrorMessage ? fieldErrorMessage : $t("FormErrors.FieldRequired")
      }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import contactApi from "@/http/contact";

export default {
  name: "ClientListSearch",
  props: [
    "placeholder",
    "modelValue",
    "errorKey",
    "iconLeft",
    "required",
    "error",
    "errorMessage",
    "updateComponent",
    "autoFocus",
    "addNewDisabled",
    "small",
    "documentView",
  ],
  data: () => ({
    fieldValue: "",
    searchResults: [],
    resultsUpdater: 0,
    customerSelected: false,
    isOpen: false,
    items: [],
    page: 1,
    scrollTimeout: null,
    fieldDisabled: false,
    fieldRequired: false,
    fieldStyle: {
      border: "1px solid #E6E8EC",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      padding: "7px 11px",
      gap: "12px",
      background: "white",
      cursor: "text",
    },
    labelStyle: {
      color: "#75787A",
    },
    fieldError: false,
    fieldErrorMessage: "",
    loading: false,
    isFetching: false,
    hasMoreItems: true,
    debounceTimeout: null,
    isNewSearch: false,
  }),
  computed: {},
  emits: ["itemChosen"],
  watch: {
    updateComponent: function () {
      if (this.modelValue && this.modelValue.contactName) {
        this.selectedCustomer = this.modelValue.contactName;
        this.customerSelected = true;
        this.resultsUpdater++;
        this.labelStyle.color = "#75787A";
        this.isOpen = false;
      }
    },
    async modelValue() {
      if (this.modelValue && this.modelValue.contactName) {
        this.selectedCustomer = this.modelValue.contactName;
        this.customerSelected = true;
        this.resultsUpdater++;
        this.focusInputElementPlaceholder();
        this.isOpen = false;
      }
    },
    fieldValue: {
      handler(val) {
        if (this.debounceTimeout) {
          clearTimeout(this.debounceTimeout);
        }
        this.debounceTimeout = setTimeout(() => {
          this.searchAndUpdateCustomers(val);
        }, 500);
      },
      deep: true,
      immediate: true,
    },
    customerSelected() {
      if (this.customerSelected) {
        this.fieldValue = this.selectedCustomer;
        if (this.$refs.input) {
          this.$refs.input.style.height = "24px";
        }
      }
      this.customerSelected = false;
    },
    defocus() {
      this.deactivatePlaceholder();
    },
    error() {
      this.checkError();
    },
    errorMessage() {
      this.fieldErrorMessage = this.errorMessage;
    },
  },
  created() {
    if (this.small) {
      this.fieldStyle.height = "32px";
    }
  },
  mounted() {
    this.initialize();
    this.checkError();
    if (this.$refs.scrollableArea) {
      this.$refs.scrollableArea.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    if (this.$refs.scrollableArea) {
      this.$refs.scrollableArea.removeEventListener(
        "scroll",
        this.handleScroll,
      );
    }
  },
  methods: {
    checkError() {
      this.fieldError = this.error;
      if (this.fieldError) {
        this.fieldStyle.border = "2px solid #FF1E24";
        this.fieldStyle.padding = "6px 10px";
        if (this.fieldFocus) {
          if (this.$refs.label) {
            this.$refs.label.style.color = "#FF1E24";
          }
        }
      } else {
        this.fieldStyle.border = "1px solid #E6E8EC";
        this.fieldStyle.padding = "7px 11px";
        if (this.$refs.label) {
          this.$refs.label.style.color = "";
          if (this.validateInput(this.fieldValue)) {
            this.$refs.label.style.color = "#FF5C01";
          }
        }
      }
    },
    async searchCustomers(page = 1) {
      if (this.isFetching) {
        return;
      }
      this.isFetching = true;
      const response = await axios.get("/api/customers/" + page, {
        params: {
          searchString: this.fieldValue,
        },
      });
      this.isFetching = false;
      const customers = response.data.customers || [];
      this.total = response.data.total || 0;
      if (this.items.length + customers.length >= this.total) {
        this.hasMoreItems = false;
      }
      return customers;
    },
    async searchAndUpdateCustomers(val) {
      this.searchResults = [];
      this.page = 1;
      if (val) {
        let contactId = val.contactId || val.customerId;
        if (contactId) {
          this.items.forEach((item) => {
            if (item.customerId === contactId) {
              this.chooseResultItem(item);
            }
          });
        } else {
          this.isNewSearch = true;
          this.items = [];
          const newItems = await this.searchCustomers(1);
          if (newItems) {
            this.items = newItems;
            this.hasMoreItems = this.items.length < this.total;
          }
          this.isNewSearch = false;
        }
      } else {
        this.isNewSearch = true;
        this.items = [];
        const newItems = await this.searchCustomers(1);
        if (newItems) {
          this.items = newItems;
          this.hasMoreItems = this.items.length < this.total;
        }
        this.isNewSearch = false;
      }
      this.resultsUpdater++;
    },
    async handleScroll() {
      const scrollableArea = this.$refs.scrollableArea;
      const offset = 300;
      if (
        scrollableArea.scrollTop + scrollableArea.clientHeight >=
        scrollableArea.scrollHeight - offset
      ) {
        if (!this.isFetching && this.hasMoreItems && !this.isNewSearch) {
          const customers = await this.searchCustomers(this.page);
          this.page++;
          this.items = [...this.items, ...customers];
        }
      }
    },
    async initialize() {
      this.loading = true;
      const allCustomers = await this.searchCustomers(
        this.page,
        this.fieldValue,
      );
      this.items = [...this.items, ...allCustomers];

      if (this.modelValue && this.modelValue.contactId) {
        if (
          !this.items.some(
            (item) => item.customerId === this.modelValue.contactId,
          )
        ) {
          const fetchItem = await contactApi.getContactById(
            this.modelValue.contactId,
          );
          this.items.push(fetchItem);
        }
        this.fieldValue = this.modelValue;
        if (this.$refs.input) {
          this.$refs.input.style.height = "24px";
        }
        this.$nextTick(() => {
          if (this.$refs.label) {
            this.$refs.label.classList.add("required-mark");
            this.$refs.label.classList.add(
              "form-field-large-active-placeholder",
            );
          }
        });
        this.closeDropdown();
      }
      if (this.required) {
        this.fieldRequired = this.required;
      }
      if (this.error) {
        this.fieldError = this.error;
      }
      if (this.errorMessage) {
        this.fieldErrorMessage = this.errorMessage;
      }
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
      this.loading = false;
      if (this.autoFocus) {
        this.$nextTick(() => {
          this.focusInputElement();
        });
      }
      this.$emit("ready");
    },
    focusInputElement() {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    closeDropdown() {
      this.isOpen = false;
      this.unfocusInput();
      this.$emit("closed");
    },
    unfocusInput() {
      if (!this.validateInput(this.fieldValue) || !this.fieldValue) {
        if (this.$refs.input) {
          this.$refs.input.style.height = "0px";
        }
        this.deactivatePlaceholder();
      }
      this.fieldFocus = false;
      this.labelStyle.color = "#75787A";

      if (!this.fieldValue) {
        this.$emit("itemChosen", null);
      }
    },
    dropdownAddNewButton() {
      this.closeDropdown();
      this.$emit("addNew");
    },
    chooseResultItem(person) {
      if (!person) {
        if (this.fieldValue) {
          this.$emit("itemChosen", null);
        }
        this.closeDropdown();
        this.deactivatePlaceholder();
        this.$nextTick(() => {
          if (this.$refs.input) {
            this.$refs.input.style.height = "0px";
          }
        });
        this.fieldValue = "";
        return;
      }
      this.fieldValue = null;
      this.customerSelected = true;
      this.selectedCustomer = person.customerName;
      this.$emit("itemChosen", person);
      this.isOpen = false;
      this.searchResults = [];
      this.resultsUpdater++;
    },
    validateInput(value) {
      return !(value === "" || /^\s*$/.test(value));
    },
    focusInputElementPlaceholder() {
      this.activatePlaceholder();
      if (!this.isOpen) {
        this.isOpen = true;
      }
      this.fieldFocus = true;
      if (this.$refs.input) {
        this.$refs.input.style.transition = "0.2s";
        this.$refs.input.style.height = "24px";
      }
    },
    activatePlaceholder(fieldActive = true) {
      if (!this.$refs.label || !this.labelStyle) return;
      this.$nextTick(() => {
        if (this.$refs.label) {
          this.$refs.label.classList.add("required-mark");
          this.$refs.label.classList.add("form-field-large-active-placeholder");
        }
      });
      if (this.fieldError) {
        this.labelStyle.color = "#FF1E24";
        return;
      }
      if (fieldActive) {
        this.labelStyle.color = "#FF5C01";
        return;
      }
      if (!fieldActive) {
        this.labelStyle.color = "#75787A";
      }
    },
    deactivatePlaceholder() {
      if (this.defocus) {
        if (this.$refs.input) {
          this.$refs.input.style.height = "0px";
        }
      }
      if (this.$refs.label) {
        this.$refs.label.classList.remove(
          "form-field-large-active-placeholder",
        );
        this.$refs.label.classList.remove("required-mark");
      }
      this.labelStyle.color = "#75787A";
    },
    removeFieldContent() {
      this.fieldValue = "";
      this.customerSelected = false;
      this.selectedCustomer = "";
      this.$emit("removeValue");
      this.unfocusInput();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.main {
  position: relative;
  width: 100%;
  height: 100%;
}
.form-field-large {
  height: 52px;
  width: 100%;
}

.form-field-large label {
  color: #75787a;
  cursor: text;
}

.form-field-large-active-placeholder {
  height: 12px !important;
  line-height: 12px !important;
  font-size: 11px !important;
}

.input-field-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.input-field {
  width: 100%;
  height: 0px;
  outline: none;
  cursor: text;
}

.items {
  box-sizing: border-box;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 1px 10px 1px rgba(0, 0, 0, 0.08),
    0px 3px 10px -2px rgba(0, 0, 0, 0.03);
  z-index: 111;
  position: absolute;
  background-color: white;
  width: 100%;
  margin-top: 8px;
  border-radius: 8px;
}

.item-wrapper {
  max-height: 200px;
  overflow: auto;
}

.item {
  min-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;

  &:hover {
    background-color: #f4f5f7;
    cursor: pointer;
  }
}

.item:focus {
  outline: 0;
  background-color: #f4f5f7;
}

.no-results {
  min-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
}

.required-mark::after {
  font-size: 12px !important;
  line-height: unset !important;
  position: absolute !important;
}

.required label::after {
  content: "*";
  color: #ff1e24;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  position: absolute !important;
}

.add-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  align-items: center;
  background-color: white;
  gap: 8px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: $orange;

  &:hover {
    cursor: pointer;
  }
}

.no-hover {
  cursor: default !important;
  background-color: white !important;
}

.error-message {
  color: #ff1e24 !important;
}

.remove-button {
  display: flex;
  cursor: pointer;

  img {
    filter: invert(47%) sepia(3%) saturate(10%) hue-rotate(334deg)
      brightness(98%) contrast(91%);
  }
}

p,
span {
  font-size: 14px;
  line-height: 20px;
}

span {
  color: #939597;
}
</style>
