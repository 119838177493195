<template>
  <div class="user-info-container">
    <div class="user-info" @click="toggleMenu">
      <div class="user-icon">
        <span class="font-20-medium">
          {{ user.name.charAt(0).toUpperCase() }}
        </span>
      </div>
      <span class="user-name">
        {{ user.name }}
      </span>
      <img :src="require('@/assets/images/chevron_down_20_20.svg')" alt="" />
    </div>

    <div class="info-menu" v-if="dropdownOpen" v-click-outside="closeMenu">
      <h5>{{ user.name }}</h5>
      <div class="font-16-regular">{{ user.email }}</div>

      <div
        class="content-medium flex-row"
        style="margin-top: 16px; align-items: center"
      >
        <img :src="require('@/assets/images/palm34.svg')" alt="" />
        <p style="margin-left: 4px">
          {{ this.$t("SetVacationPeriod") + " (Coming soon)" }}
        </p>
      </div>
      <div
        style="margin-top: 16px; margin-bottom: 16px; height: 44px"
        class="flex-row"
      >
        <LanguageSelection
          :closed-panel="openConfirmationPanel"
          @confirmationOpen="openConfirmPanel"
        />
      </div>
      <div
        class="menu-item"
        style="padding: 0px"
        @click="() => selectItem('settings')"
      >
        <img
          :src="require(`../../assets/images/admin.svg`)"
          alt=""
          class="img24"
        />

        <div class="content">
          {{ this.$t("AccountSettings") }}
        </div>
      </div>
      <div style="padding: 0px; margin-top: 16px">
        <div class="flex-row">
          <p class="content-small-gray">{{ this.$t("AnyQuestions") }}</p>
        </div>
        <div style="margin-top: 4px; margin-bottom: 16px" class="flex-row">
          <p class="content-medium">support@runproperty.com</p>
        </div>
      </div>

      <div style="margin-bottom: 24px" class="separator"></div>

      <div class="user-info">
        <div class="user-icon">
          <span class="font-20-medium">
            {{ user.access.company.name.charAt(0).toUpperCase() }}
          </span>
        </div>
        <div
          class="sub-class"
          style="display: flex; flex-wrap: wrap; flex-direction: column"
        >
          <span class="user-name content-medium">
            {{ user.access.company.name }}
          </span>
          <span class="user-name content-small-gray">
            {{ getUserHomeGroup() }}
          </span>
          <span class="user-name content-small-orange">
            {{ formatUserAccess() }}
          </span>
        </div>
      </div>
      <div
        style="margin-top: 24px; margin-bottom: 16px"
        class="separator"
      ></div>
      <div
        class="menu-item"
        style="padding: 0px"
        @click="() => selectItem('logout')"
      >
        <img
          :src="require(`../../assets/images/log-out.svg`)"
          alt=""
          class="img24"
        />

        <div class="content">
          {{ this.$t("Logout") }}
        </div>
      </div>
    </div>
    <ConfirmationModal
      @onConfirm="emitConfirmationModalConfirm()"
      @onCancel="closeConfirmationPanel()"
      :text-start-icon="'info.svg'"
      v-if="openConfirmationPanel"
    >
      <p style="padding: 2px 0" class="content-normal">
        {{ "Are you sure you want to change language?" }}
      </p>
    </ConfirmationModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import LanguageSelection from "@/components/common/LanguageSelection";
import ConfirmationModal from "@/components/common/ConfirmationModal";
import axios from "axios";

export default {
  name: "TheInfo",
  components: {
    LanguageSelection,
    ConfirmationModal,
  },
  data() {
    return {
      dropdownOpen: false,
      openConfirmationPanel: false,

      newSelectedLanguage: null,
      menuItems: [
        {
          name: "settings",
          iconUrl: "admin.svg",
          field: `${this.$t("MyDetails")}`,
        },
        {
          name: "logout",
          iconUrl: "log-out.svg",
          field: `${this.$t("Logout")}`,
        },
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["logout", "setLanguage", "setLanguageSet"]),
    toggleMenu() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    openConfirmPanel(boolean, value) {
      this.openConfirmationPanel = boolean;
      this.newSelectedLanguage = value;
    },
    closeConfirmationPanel() {
      console.log("TIME TO CLOSE", this.openConfirmationPanel);
      this.openConfirmationPanel = false;
    },
    async emitConfirmationModalConfirm() {
      let body = {
        userId: this.user._id,
        companyId: this.user.access.company._id,
        selectedLanguage: this.newSelectedLanguage,
      };
      await axios.post("/api/update-user-language", body);
      this.user.selectedLanguage = this.newSelectedLanguage;
      this.changeLocale(this.newSelectedLanguage);
      this.openConfirmationPanel = false;
    },
    changeLocale(loc) {
      this.setLanguage(loc);
      this.setLanguageSet(true);
      this.$i18n.locale = loc;
      location.reload();
    },
    closeMenu() {
      this.dropdownOpen = false;
    },
    getUserHomeGroup() {
      let homeGroup = "-";
      for (let [key, value] of Object.entries(this.user.access.groups)) {
        if (value.isDefault && this.user.access.groups[key].isDefault) {
          homeGroup = value.name;
        }
      }
      return homeGroup;
    },
    formatUserAccess() {
      if (this.user.access.accessLevel === "company_admin") {
        return this.$t("Admin");
      } else if (this.user.accessLevel === "group_admin") {
        return this.$t("GroupAdmin");
      } else {
        return this.$t("User");
      }
    },
    selectItem(itemName) {
      switch (itemName) {
        case "settings":
          this.$router.push({ name: "BrokerSettings" });
          break;
        case "logout":
          this.logOut();
          break;
      }
      this.closeMenu();
    },
    logOut() {
      this.logout();
      this.$router.push("/login");
      Cookies.remove("token");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.user-info-container {
  display: flex;
  justify-content: flex-end;
}

.menu-item {
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-self: stretch;
  padding: 12px 16px;
  align-items: center;
}
.info-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: absolute;
  margin-top: 45px;
  max-height: 440px;
  max-width: 340px;
  min-width: 300px;
  overflow: hidden;
  padding: 24px;
  z-index: 20;
  background-color: white;

  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 8px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }
}

.user-icon {
  border-radius: 50%;
  padding: 0 4px;
  background: #000000;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-name {
  color: black;
  font-size: 14px;
  overflow-wrap: break-word;
  margin-right: auto;
}
.separator {
  border: 1px solid rgba(230, 232, 236, 1);
  width: 100%;
  height: 1px;
}
</style>
