<template>
  <div id="overlay-customer-addmodal" class="overlay-addmodal">
    <v-card class="modal-wrapper" style="border-radius: 0">
      <div class="sticky-header">
        <div style="padding-left: 24px" class="modal-header">
          <h4 v-if="!isEdit" class="modal-title">{{ $t("AddContact") }}</h4>
          <h4 v-if="isEdit" class="modal-title">{{ $t("ChangeClient") }}</h4>
        </div>
        <v-divider
          style="top: 98px; z-index: 111; position: fixed; width: 640px"
        ></v-divider>
      </div>

      <v-tabs-items v-model="tab">
        <v-tab-item style="padding-bottom: 106px">
          <v-card class="form-wrapper" flat>
            <div class="flex-row" style="margin-top: 98px">
              <h6>{{ $t("ContactDetails") }}</h6>
            </div>
            <div
              class="flex-row input-wrapper"
              style="margin-top: 12px; max-height: fit-content"
            >
              <div
                class="flex-row"
                style="
                  display: flex;
                  flex-wrap: nowrap;
                  gap: 12px;
                  margin-top: 4px;
                "
              >
                <BaseInput
                  :id="'sidepanel-contact-firstname'"
                  v-model="privatePerson.firstName"
                  :error-message="
                    errors && errors.firstName
                      ? $t('FormErrors.' + errors.firstName)
                      : ''
                  "
                  :error="isFilledFirstName && !isFieldValid('firstNameNew')"
                  type="text"
                  :placeholder="$t('FirstName')"
                  required="true"
                />
                <BaseInput
                  id="sidepanel-contact-lastname"
                  v-model="privatePerson.lastName"
                  :placeholder="$t('LastName')"
                />
              </div>
              <div class="flex-row" style="margin-top: 0px !important">
                <BaseToggle
                  v-model="privatePerson.contactIsPartner"
                  @click="changeContactPartnerStatus($event, true)"
                  :label="$t('ContactIsPartner')"
                />
              </div>
              <BaseDropdown
                :disabled="
                  customerData &&
                  !user.isAdmin &&
                  !user.isGroupAdmin &&
                  privatePerson.creator.userId !== user._id
                "
                :id="'sidepanel-contact-ownership'"
                :items="clientOwnerships"
                ref="visibilityDropDown"
                v-model="privatePerson.clientOwnership"
                :dropdown-allow-null="false"
                :key="updValue"
                :placeholder="$t('Visibility.Label')"
                :show-dropdown-arrow="true"
              />
              <v-col
                v-if="privatePerson.clientOwnership === 'GROUP'"
                style="padding: 0"
              >
                <DropdownMulti
                  :items="contactGroupSelection"
                  :selected-items="preselectedGroups"
                  :height="'52px'"
                  :display-key="'name'"
                  :value-key="'name'"
                  :field-type="$t('Group')"
                  :enable-search="true"
                  @input="updateSelectedItems"
                />
              </v-col>
              <div
                class="flex-row"
                v-for="(email, index) in privatePerson.emails"
                :key="index"
                style="gap: 26px; flex-wrap: nowrap; width: 100%; margin-top: 0"
              >
                <BaseInput
                  :id="'sidepanel-contact-email-' + index"
                  icon-left="email_20_20.svg"
                  :name="'email-input-' + index"
                  :placeholder="$t('CustomerField.email')"
                  v-model="email.email"
                  :spaces-removal="true"
                />
                <div
                  class="listing-title-row"
                  style="cursor: pointer; margin-top: 18px; margin-right: 14px"
                >
                  <img
                    name="extra-email-button"
                    @click="addEmptyForm()"
                    v-if="index == 0"
                    :src="require('@/assets/images/PlusButton.svg')"
                    alt=""
                  />
                  <img
                    v-if="index != 0"
                    :src="require('@/assets/images/MinusButton.svg')"
                    @click="removeForm(index)"
                    alt=""
                  />
                </div>
              </div>
              <div
                class="flex-row"
                v-for="(phoneNumber, index) in privatePerson.phoneNumbers"
                :key="phoneNumber._id"
                style="gap: 26px; flex-wrap: nowrap; margin-top: 0; width: 100%"
              >
                <BaseInput
                  :id="'sidepanel-contact-phone-' + index"
                  icon-left="phone_20_20.svg"
                  :placeholder="$t('CustomerField.phoneNumber')"
                  v-model="phoneNumber.phoneNumber"
                />

                <div
                  class="listing-title-row"
                  style="cursor: pointer; margin-top: 18px; margin-right: 14px"
                >
                  <img
                    @click="addEmptyFormPhoneNumbers()"
                    v-if="index == 0"
                    :src="require('@/assets/images/PlusButton.svg')"
                    alt=""
                  />
                  <img
                    v-if="index != 0"
                    :src="require('@/assets/images/MinusButton.svg')"
                    @click="removeFormPhoneNumbers(index)"
                    alt=""
                  />
                </div>
              </div>

              <div class="flex-row" style="width: 100%">
                <h6>{{ $t("Organization") }}</h6>
              </div>
              <div
                class="flex-row"
                v-for="(organization, index) in privatePerson.organizations"
                :key="organization._id"
                style="
                  margin-top: 0;
                  background-color: #f4f5f7;
                  width: 480px;
                  border-radius: 8px;
                  padding: 16px;
                "
              >
                <div
                  class="flex-row"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <v-radio-group
                    v-if="userCountry === 'ee'"
                    v-model="
                      privatePerson.organizations[index]
                        .organizationSearchingMethod
                    "
                    hide-details
                    row
                  >
                    <v-radio
                      :label="$t('Estonian')"
                      color="orange darken-3"
                      :value="'Estonian'"
                    ></v-radio>
                    <v-radio
                      :label="$t('Generic')"
                      :value="'Generic'"
                      color="orange darken-3"
                    ></v-radio>
                  </v-radio-group>
                  <div class="listing-title-row" style="cursor: pointer">
                    <div v-if="index !== 0" class="clear-row flex-row">
                      <div
                        class="clear-button"
                        type="button"
                        @click="removeFromOrganizations(index)"
                      >
                        <img
                          :src="require('@/assets/images/close.svg')"
                          alt=""
                        />
                        <p class="content-small">clear</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex-row"
                  v-if="organization.organizationSearchingMethod === 'Estonian'"
                  style="width: 100%"
                >
                  <OrganizationSearch
                    v-model="privatePerson.organizations[index]"
                    :icon-left="'search20.svg'"
                    :get-client="true"
                    :placeholder="$t('OrganizationsName')"
                    :show-completed-projects="false"
                    :dropdown-offset-y="52"
                    :key="'sidepanel-contact-organization-' + index"
                  />
                </div>
                <div
                  class="flex-row"
                  style="margin-top: 12px"
                  v-if="organization.organizationSearchingMethod === 'Generic'"
                >
                  <div class="flex-row" style="width: 100%">
                    <BaseInput
                      v-model="organization.organization"
                      :placeholder="$t('OrganizationsName')"
                    />
                  </div>
                  <div class="flex-row" style="width: 100%">
                    <GoogleAddressSearch
                      v-model="organization.address"
                      :placeholder="
                        $t('Object') + ' ' + $t('Address').toLowerCase()
                      "
                    />
                  </div>
                  <div
                    class="flex-row"
                    style="
                      margin-top: 16px;
                      gap: 12px;
                      width: 100%;
                      display: flex;
                      flex-wrap: nowrap;
                    "
                  >
                    <BaseInput
                      :placeholder="$t('CustomerField.phoneNumber')"
                      icon-left="phone_20_20.svg"
                      v-model="organization.phoneNum"
                    />
                    <BaseInput
                      :placeholder="$t('CustomerField.email')"
                      icon-left="email_20_20.svg"
                      v-model="organization.orgEmail"
                    />
                  </div>
                </div>
              </div>
              <div style="width: 100%" class="add-another-row flex-row">
                <p
                  @click="addEmptyFormOrganizations()"
                  style="cursor: pointer"
                  class="link-small"
                >
                  + {{ $t("AddAnother") }}
                </p>
              </div>

              <div class="flex-row" style="margin-top: 36px; width: 100%">
                <h6>{{ $t("DisplayTag") }}</h6>
              </div>
              <div class="flex-row" style="margin-top: 0; flex-wrap: nowrap">
                <div
                  style="
                    border: 1px solid #e6e8ec;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                  "
                >
                  <div
                    class="flex-row"
                    style="align-items: center; width: max-content"
                  >
                    <div
                      v-for="(tag, key, index) in displayedTags"
                      :key="'tag-' + index"
                      @click="setTag(key)"
                      class="tag-container"
                      v-bind:class="{
                        '': !privatePerson.tags[key],
                        'selected-font-first': privatePerson.tags[key],
                      }"
                    >
                      <div
                        :style="{ backgroundColor: tag.backgroundColor }"
                        class="tag-abbreviation"
                      >
                        <p :style="{ color: tag.textColor }" class="tag-name">
                          {{ $t(tag.abbreviation) }}
                        </p>
                      </div>

                      <p
                        v-bind:class="{
                          'content-small': !privatePerson.tags[key],
                          'content-small big-font': privatePerson.tags[key],
                        }"
                        style="margin-right: 8px"
                      >
                        {{ $t(tag.name) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <span
                v-if="!isEdit"
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  gap: 12px;
                "
              >
                <div class="flex-row" style="margin-top: 32px; display: block">
                  <h6>{{ $t("AddNote") }}</h6>
                </div>
                <BaseTextarea
                  :id="'sidepanel-contact-note'"
                  v-model="noteContent"
                  :placeholder="$t('Note')"
                />
              </span>
              <div
                class="flex-row"
                style="margin-top: 0; width: 100%; padding-top: 28px"
              >
                <h6>{{ $t("AdditionalDetails") }}</h6>
              </div>
              <div
                class="flex-row"
                style="
                  gap: 12px;
                  display: flex;
                  margin-top: 0;
                  width: 100%;
                  flex-wrap: nowrap;
                "
              >
                <BaseDropdown
                  icon-left="calendar_20_20.svg"
                  :id="'sidepanel-contact-birth-date'"
                  :placeholder="$t('BirthdayDate')"
                  v-model="privatePerson.birthday"
                  :show-dropdown-arrow="true"
                  type="date"
                />
                <BaseDropdown
                  :id="'sidepanel-contact-gender'"
                  :items="genders"
                  :show-dropdown-arrow="true"
                  :placeholder="$t('CustomerField.Gender.Label')"
                  v-model="privatePerson.sex"
                />
              </div>
              <div style="display: flex; width: 100%; gap: 12px">
                <BaseInput
                  :id="'sidepanel-contact-personalcode'"
                  v-model="privatePerson.personalCode"
                  :placeholder="$t('CustomerField.personalCode')"
                />
                <BaseDropdown
                  :id="'sidepanel-contact-language'"
                  :items="languages"
                  :dropdown-z-index="1000"
                  v-model="privatePerson.customerLang"
                  :placeholder="$t('CustomerField.CustomerLanguage.Label')"
                  :show-dropdown-arrow="true"
                />
              </div>
              <BaseInput
                :id="'sidepanel-contact-bank-account'"
                v-model="privatePerson.bankAccountNumber"
                :placeholder="$t('CustomerField.BankAccountNumber')"
              />
              <BaseInput
                :id="'sidepanel-contact-address'"
                v-model="privatePerson.address"
                :placeholder="$t('CustomerField.address')"
                icon-left="navigation_20_20.svg"
              />
              <div class="flex-row" style="gap: 12px; flex-wrap: nowrap">
                <BaseInput
                  :id="'sidepanel-contact-postalcode'"
                  v-model="privatePerson.postalCode"
                  :placeholder="$t('PostalCode')"
                />
                <BaseDropdown
                  :id="'sidepanel-contact-country'"
                  v-model="privatePerson.countryCode"
                  :items="countries"
                  :return-object="true"
                  :placeholder="$t('CustomerField.Country.Label')"
                  :show-dropdown-arrow="true"
                  :dropdown-offset-y="-250"
                />
              </div>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="buttons-container" style="width: 640px">
        <v-divider
          style="z-index: 111; position: absolute; width: 640px"
        ></v-divider>
        <div
          style="
            padding-right: 32px;
            padding-top: 24px;
            padding-left: 32px;
            display: flex;
            justify-content: flex-end;
          "
          :class="{ 'edit-footer': isEdit }"
        >
          <button
            name="contact-delete-button"
            v-if="isEdit"
            style="align-self: flex-start"
            class="delete-button"
            color="blue darken-1"
            text
            @click="deletePanelOpened = true"
          >
            <img
              style="padding-right: 8px"
              :src="require('@/assets/images/TrashBin.svg')"
              alt=""
            />
            {{ $t("Delete") }}
          </button>
          <div style="display: flex; flex-wrap: nowrap">
            <CancelButton @clicked="closeModal()" />

            <SubmitButton
              v-if="isEdit"
              :loading="isLoading"
              @clicked="updateCustomer(privatePerson)"
            />
            <SubmitButton
              name="contact-save-button"
              v-if="!isEdit"
              :loading="isLoading"
              @clicked="submitCustomer()"
            />
          </div>
        </div>
      </div>
    </v-card>
    <DeleteModal
      style="z-index: 121"
      :removing="false"
      @canceled="deletePanelOpened = false"
      @approved="deleteCustomer()"
      v-if="deletePanelOpened"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

const countries_et = require("../../components/common/countries_et.js");
const countries_en = require("../../components/common/countries_en.js");
import { nanoid } from "nanoid";
import SubmitButton from "@/components/common/SubmitButton";
import contactApi from "@/http/contact";
import CancelButton from "@/components/common/CancelButton";
import BaseInput from "@/components/common/BaseInput";
import BaseDropdown from "@/components/common/BaseDropdown";
import GoogleAddressSearch from "@/components/common/GoogleAddressSearch";
import BaseTextarea from "@/components/common/BaseTextarea";
import _ from "lodash";
import BaseToggle from "../common/BaseToggle";
import DropdownMulti from "@/components/common/DropdownMulti";
import moment from "moment";
import axios from "axios";
import DeleteModal from "@/components/common/DeleteModal.vue";
import OrganizationSearch from "@/components/common/OrganizationSearch";

export default {
  name: "AddModal",
  props: [
    "customerData",
    "isEdit",
    "accordion",
    "existingEmails",
    "existingOrganizations",
    "existingPhones",
    "noRefresh",
    "notContactViewRoute",
  ],
  components: {
    DropdownMulti,
    BaseToggle,
    BaseTextarea,
    GoogleAddressSearch,
    BaseDropdown,
    CancelButton,
    SubmitButton,
    BaseInput,
    DeleteModal,
    OrganizationSearch,
  },
  data() {
    return {
      userCountry: "ee",
      groups: [],
      isLoading: false,
      updValue: 0,
      validate: ["firstNameEdit", "firstNameNew"],
      noteContent: "",
      showErrors: false,
      selectedItems: [],
      errors: null,
      closeOrganization: 0,
      dialog: false,
      dialogm1: null,
      tab: 0,
      menu: false,
      deletePanelOpened: false,
      countries: null,
      languages: [
        { name: this.$t("Estonian"), value: "ESTONIAN" },
        { name: this.$t("Russian"), value: "RUSSIAN" },
        { name: this.$t("English"), value: "ENGLISH" },
        { name: this.$t("Finnish"), value: "FINNISH" },
        { name: this.$t("German"), value: "GERMAN" },
      ],
      genders: [
        { name: this.$t("Male"), value: "MALE" },
        { name: this.$t("Female"), value: "FEMALE" },
      ],
      clientOwnerships: [],
      customerTypes: [
        "Buyer",
        "Seller",
        "Landlord",
        "Tenant",
        "Appraisement",
        "Business appraisement",
        "Partner",
        "Broker partner",
      ],
      isFilledName: false,
      //isFilledEmail: false,
      isFilledFirstName: false,
      isFilledLastName: false,
      isFilledCustomerType: false,
      object: null,
      privatePerson: {
        customerSector: "Private persons",
        customerName: "",
        firstName: "",
        lastName: "",
        organization: "",
        registrationNumber: "",
        personalCode: "",
        registrationDate: "",
        countryCode: "EE",
        clientOwnership: "PRIVATE",
        groupIds: [],
        phoneNumber: "",
        address: "",
        postalCode: "",
        bankAccountNumber: "",
        //organizationSearchingMethod: 'Estonian',
        email: null,
        emails: [
          {
            _id: nanoid(16),
            email: null,
          },
        ],
        organizations: [
          {
            _id: nanoid(16),
            organization: null,
            organizationSearchingMethod: "Estonian",
            address: null,
            objects: [],
            phoneNum: null,
            orgEmail: null,
            reg_code: null,
            company_name: null,
          },
        ],
        phoneNumbers: [
          {
            _id: nanoid(16),
            phoneNumber: null,
          },
        ],
        birthday: null,
        relatedPersons: [],
        notes: [],
        contactOwner: "",
        customerType: "",
        visibility: "",
        customerLang: "",
        sex: "",
        //client: false,
        tags: {
          propertyOwner: false,
          landlord: false,
          buyer: false,
          tenant: false,
          other: false,
          photographer: false,
          valuator: false,
          notary: false,
          bank_representative: false,
          otherInPartner: false,
        },
        contactIsPartner: false,
      },
      initialTags: {
        propertyOwner: {
          value: false,
          backgroundColor: "#b5e7cf",
          textColor: "black",
          name: "PropertyOwner",
          abbreviation: "PO",
        },
        landlord: {
          value: false,
          backgroundColor: "#b8d8ff",
          textColor: "black",
          name: "Landlord",
          abbreviation: "L",
        },
        buyer: {
          value: false,
          backgroundColor: "#fff2b3",
          textColor: "black",
          name: "Buyer",
          abbreviation: "B",
        },
        tenant: {
          value: false,
          backgroundColor: "#fcbae2",
          textColor: "black",
          name: "Tenant",
          abbreviation: "T",
        },
        other: {
          value: false,
          backgroundColor: "#c3c4c6",
          textColor: "black",
          name: "Other",
          abbreviation: "O",
        },
        photographer: {
          value: false,
          backgroundColor: "#16AFB9",
          textColor: "white",
          name: "Photographer",
          abbreviation: "PH",
          isPartnerTag: true,
        },
        valuator: {
          value: false,
          backgroundColor: "#AA086A",
          textColor: "white",
          name: "Valuator",
          abbreviation: "VA",
          isPartnerTag: true,
        },
        notary: {
          value: false,
          backgroundColor: "#4C8DD9",
          textColor: "white",
          name: "Notary",
          abbreviation: "NO",
          isPartnerTag: true,
        },
        bank_representative: {
          value: false,
          backgroundColor: "#B54616",
          textColor: "white",
          name: "BankRepresentative",
          abbreviation: "BA",
          isPartnerTag: true,
        },
        otherInPartner: {
          value: false,
          backgroundColor: "#75787a",
          textColor: "white",
          name: "Other",
          abbreviation: "O",
          isPartnerTag: true,
        },
      },
    };
  },
  async created() {
    if (this.customerData) {
      this.privatePerson = _.cloneDeep(this.customerData);

      const newTags = {
        photographer: this.customerData.tags.photographer
          ? this.customerData.tags.photographer
          : false,
        valuator: this.customerData.tags.valuator
          ? this.customerData.tags.valuator
          : false,
        notary: this.customerData.tags.notary
          ? this.customerData.tags.notary
          : false,
        bank_representative: this.customerData.tags.bank_representative
          ? this.customerData.tags.bank_representative
          : false,
        otherInPartner: this.customerData.tags.otherInPartner
          ? this.customerData.tags.otherInPartner
          : false,
      };
      this.privatePerson.tags = { ...this.privatePerson.tags, ...newTags };
    }

    this.setupVisibilityValues();

    if (this.$i18n.locale == "et") {
      this.countries = Object.values(countries_et);
      this.countries = this.countries[0];
      this.countries = Object.keys(this.countries).map((key) => ({
        value: key,
        name: this.countries[key],
      }));
    }
    if (this.$i18n.locale == "en") {
      this.countries = Object.values(countries_en);
      this.countries = this.countries[0];
      this.countries = Object.keys(this.countries).map((key) => ({
        value: key,
        name: this.countries[key],
      }));
    }
    if (this.isEdit) {
      if (this.privatePerson.birthday) {
        this.privatePerson.birthday = moment(
          this.privatePerson.birthday,
        ).format("YYYY-MM-DD");
      }
      if (this.privatePerson.phoneNumbers.length === 0) {
        let emptyForm = {
          _id: nanoid(16),
          phoneNumber: null,
        };
        this.privatePerson.phoneNumbers.push(emptyForm);
      }
      if (this.privatePerson.emails.length === 0) {
        let emptyForm = {
          _id: nanoid(16),
          email: null,
        };
        this.privatePerson.emails.push(emptyForm);
      }
      if (this.privatePerson.organizations.length === 0) {
        let emptyForm = {
          _id: nanoid(16),
          organization: null,
        };
        this.privatePerson.organizations.push(emptyForm);
      }
    } else {
      const userDefaultGroup = this.user.groups.find(
        (group) => group.userDefault,
      );
      if (userDefaultGroup) {
        this.privatePerson.groupIds = [userDefaultGroup._id];
      }

      this.privatePerson.clientOwnership =
        this.user.companyPolicies.visibility.default_visibility;
    }
    if (this.activeContactsTab === "partners") {
      this.changeContactPartnerStatus(true, false);
    }
    await this.setupCompany();
  },
  mounted() {
    document.documentElement.style.overflow = "hidden";
    window.onclick = (e) => {
      if (e.target.classList[0] === "overlay-addmodal") {
        this.closeModal();
        this.$emit("panelClosed");
        this.setClientSidepanel(0);
        this.changeModalStateDeals(0);
        e.target.style.display = "none";
      }
    };
  },
  beforeUnmount() {
    if (!this.isEdit && !this.accordion) {
      document.documentElement.style.overflow = "visible";
    }
  },
  watch: {
    "privatePerson.organizations": function () {
      this.privatePerson.organizations.forEach((org, index) => {
        if (org === null) {
          this.privatePerson.organizations.splice(index, 1);
        }
      });
      if (this.privatePerson.organizations.length === 0) {
        this.addEmptyFormOrganizations();
      }
    },
    "privatePerson.clientOwnership": function () {
      if (this.privatePerson.clientOwnership === "GROUP" && !this.isEdit) {
        for (const groupId in this.user.access.groups) {
          if (this.user.access.groups[groupId].isDefault) {
            this.privatePerson.groupIds = [groupId];
            this.preselectedGroups = this.contactGroupSelection.filter(
              (group) => this.privatePerson.groupIds.includes(group._id),
            );
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters(["user", "activeContactsTab"]),
    contactGroupSelection() {
      if (this.user.access.accessLevel === "company_admin") {
        //admin if creating smth sees only his groups
        let groups = [];
        for (const groupKey in this.groups) {
          let groupId = this.groups[groupKey].value;
          if (this.user.access.groups[groupId]) {
            groups.push({
              name: this.groups[groupKey].name,
              value: { _id: groupId, ...this.groups[groupKey] },
            });
          }
        }
        if (this.isEdit) {
          //admin if editing smth sees all groups (v.a enda loodud asjad)
          if (!this.privatePerson.access.users[this.user._id]) {
            const groups = [];
            for (const groupId in this.groups) {
              groups.push({
                name: this.groups[groupId].name,
                value: { _id: groupId, ...this.groups[groupId] },
              });
            }
            return groups;
          }
        }
        return groups;
      } else {
        let userGroups = [];
        for (const groupKey in this.groups) {
          let groupId = this.groups[groupKey].value;

          if (this.user.access.groups[groupId]) {
            userGroups.push({
              name: this.groups[groupKey].name,
              value: { _id: groupId, ...this.groups[groupKey] },
            });
          }
        }
        return userGroups;
      }
    },
    preselectedGroups() {
      if (!this.contactGroupSelection.length || !this.privatePerson.groupIds)
        return [];

      return this.contactGroupSelection.filter((group) =>
        this.privatePerson.groupIds.includes(group.value.value),
      );
    },
    customerName() {
      return this.privatePerson.firstName + " " + this.privatePerson.lastName;
    },
    customerNameEdit() {
      return this.privatePerson.firstName + " " + this.privatePerson.lastName;
    },
    displayedTags() {
      if (this.privatePerson.contactIsPartner) {
        let newObj = {};
        for (let key in this.initialTags) {
          let obj = this.initialTags[key];
          if (obj.isPartnerTag === true) {
            newObj[key] = obj;
          }
        }
        return newObj;
      } else {
        let newObj = {};
        for (let key in this.initialTags) {
          let obj = this.initialTags[key];
          if (!obj.isPartnerTag) {
            newObj[key] = obj;
          }
        }
        return newObj;
      }
    },
  },
  methods: {
    ...mapActions([
      "changeClientAddModal",
      "setClientSidepanel",
      "changeModalStateDeals",
      "setNewAddedContact",
    ]),
    async getCompanyGroups() {
      try {
        const response = await axios.get(
          `/api/get-groups/${this.user.companyId}`,
        );
        if (response && response.data) {
          let groups = [];
          for (const groupId in response.data.access.groups) {
            groups.push({
              name: response.data.access.groups[groupId].name,
              value: groupId,
            });
          }
          groups.sort(function (a, b) {
            var textA = a.name.toLowerCase();
            var textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          this.groups = groups;
        }
      } catch (error) {
        console.error("Error fetching company groups:", error);
      }
    },
    updateSelectedItems(selectedItems) {
      this.selectedItems = selectedItems;
    },
    selectGroups(selectedGroups) {
      this.privatePerson.groupIds = selectedGroups.map((group) => group._id);
    },
    changeContactPartnerStatus(status, tagResetting) {
      this.privatePerson.contactIsPartner = status;
      if (this.privatePerson.contactIsPartner && !this.isEdit) {
        if (
          this.user.access.accessLevel === "user" &&
          this.user.companyPolicies.visibility.default_visibility === "COMPANY"
        ) {
          this.privatePerson.clientOwnership = "COMPANY";
        } else {
          this.privatePerson.clientOwnership = "GROUP";
          this.updValue++;
        }
      }

      if (tagResetting) {
        for (const [key] of Object.entries(this.privatePerson.tags)) {
          this.privatePerson.tags[key] = false;
        }
      }
    },
    async setupCompany() {
      const companyResponse = await axios.get(
        `/api/company/info/${this.user.access.company._id}`,
      );
      const company = companyResponse.data;
      if (company.country && company.country !== "ee") {
        this.userCountry = company.country;
        this.privatePerson.organizations.forEach((org) => {
          org.organizationSearchingMethod = "Generic";
        });
      }
    },
    setupVisibilityValues() {
      this.getCompanyGroups();
      let visibilityValues;
      if (this.user.isAdmin || this.user.isGroupAdmin) {
        visibilityValues = [
          { name: this.$t("Company"), value: "COMPANY" },
          {
            name: this.$t("Group"),
            value: "GROUP",
          },
          // { name: this.$t("Private+"), value: "PRIVATE+" },
          { name: this.$t("Private"), value: "PRIVATE" },
        ];
      } else {
        if (this.user.companyPolicies.visibility.limitable.customers) {
          visibilityValues = [
            { name: this.$t("Company"), value: "COMPANY" },
            {
              name: this.$t("Group"),
              value: "GROUP",
            },
            // { name: this.$t("Private+"), value: "PRIVATE+" },
            { name: this.$t("Private"), value: "PRIVATE" },
          ];
        } else {
          if (
            this.user.companyPolicies.visibility.default_visibility ===
            "PRIVATE"
          ) {
            visibilityValues = [
              { name: this.$t("Company"), value: "COMPANY" },
              {
                name: this.$t("Group"),
                value: "GROUP",
              },
              // { name: this.$t("Private+"), value: "PRIVATE+" },
              { name: this.$t("Private"), value: "PRIVATE" },
            ];
          }
          if (
            this.user.companyPolicies.visibility.default_visibility ===
            "PRIVATE+"
          ) {
            visibilityValues = [
              { name: this.$t("Company"), value: "COMPANY" },
              {
                name: this.$t("Group"),
                value: "GROUP",
              },
              { name: this.$t("Private"), value: "PRIVATE" },
            ];
          }
          if (
            this.user.companyPolicies.visibility.default_visibility === "GROUP"
          ) {
            visibilityValues = [
              { name: this.$t("Company"), value: "COMPANY" },
              {
                name: this.$t("Group"),
                value: "GROUP",
              },
              // { name: this.$t("Private+"), value: "PRIVATE+", hidden: true  },
              { name: this.$t("Private"), value: "PRIVATE" },
            ];
          }
          if (
            this.user.companyPolicies.visibility.default_visibility ===
            "COMPANY"
          ) {
            visibilityValues = [
              { name: this.$t("Company"), value: "COMPANY" },
              {
                name: this.$t("Group"),
                value: "GROUP",
                hidden: true,
              },
              // { name: this.$t("Private+"), value: "PRIVATE+" , hidden: this.user.access.company._id === 'fYOtPBZ0VFXg1gn57Rqa' ? true : false},
              { name: this.$t("Private"), value: "PRIVATE", hidden: false },
            ];
          }
        }
      }

      this.clientOwnerships = visibilityValues;
    },
    setTag(tagName) {
      this.privatePerson.tags[tagName] = !this.privatePerson.tags[tagName];
    },
    setObject(object, index) {
      this.privatePerson.organizations[index].address = object.address;
    },
    closeModal() {
      this.$emit("closed", "false");
      this.changeClientAddModal(0);
      //document.documentElement.style.overflow = "hidden";
    },
    async addCustomer(customerDetails) {
      return await contactApi.createContact(customerDetails);
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();

      return this.validate
        .map((field) => {
          if (this.isEdit && field === "firstNameNew") return true;
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "firstNameNew":
          if (
            this.privatePerson &&
            (!this.privatePerson.firstName ||
              !this.privatePerson.firstName.replace(/\s/g, "").length)
          ) {
            this.errors.firstName = "FieldRequired";
            return false;
          }

          break;
        case "firstNameEdit":
          if (
            this.privatePerson &&
            (!this.privatePerson.firstName ||
              !this.privatePerson.firstName.replace(/\s/g, "").length)
          ) {
            this.errors.firstName = "FieldRequired";
            return false;
          }

          break;
      }

      return true;
    },
    getEmptyErrors() {
      return {
        firstName: "",
      };
    },
    async updateCustomer(privatePerson) {
      if (this.isLoading) return;

      privatePerson.customerName = this.customerNameEdit;
      /*   let regExp = /[a-zA-Z]/g;
      let firstNameString = privatePerson.firstName; */
      let firstNameFilled = false;
      if (
        privatePerson &&
        privatePerson.firstName &&
        privatePerson.firstName.replace(/\s/g, "").length
      ) {
        firstNameFilled = true;
      } else {
        this.isFilledFirstName = true;
      }
      if (firstNameFilled) {
        if (privatePerson.emails[0].email != null) {
          privatePerson.email = privatePerson.emails[0].email;
        } else {
          privatePerson.email = "";
        }
        if (privatePerson.phoneNumbers[0].phoneNumber != null) {
          privatePerson.phoneNumber = privatePerson.phoneNumbers[0].phoneNumber;
        } else {
          privatePerson.phoneNumber = "";
        }
        if (privatePerson.organizations[0].organization != null) {
          privatePerson.organization =
            privatePerson.organizations[0].organization;
        } else {
          privatePerson.organization = "";
        }
        this.isLoading = true;
        if (privatePerson.organizations) {
          privatePerson.organizations.forEach((organization) => {
            if (!organization.company_name) {
              organization.company_name = organization.organization;
            }
          });
        }
        privatePerson.groupIds = this.selectedItems.map(
          (group) => group.value.value,
        );
        const response = await contactApi.updateContact(privatePerson);
        if (response === 0) {
          this.$toast.error(this.$t("ErrorUpdating"));
          this.isLoading = false;
          return;
        } else {
          this.$toast.success(
            this.$t("Contact") + " " + this.$t("Updated").toLowerCase(),
          );
          this.isLoading = false;
        }
      } else {
        if (!this.isFormValid()) {
          this.showErrors = true;
        }
        return;
      }
      await this.changeClientAddModal(0);
      this.$emit("customerUpdated");
      this.$emit("closed");
    },
    /*  addEmailField() {
      let arrowUp = document.getElementById(index + "up");
      let arrowDown = document.getElementById(index + "down");
      //let clickedKanbanRow = document.getElementById(kanbanRowId);
      if (clickedKanbanRow.style.display !== "flex") {
        clickedKanbanRow.style.display = "flex";
        clickedKanbanRow.classList.remove("kanban-close");
        clickedKanbanRow.classList.add("kanban-open");
        arrowUp.style.display = "block";
        arrowDown.style.display = "none";
      } else {
        clickedKanbanRow.classList.remove("kanban-open");
        clickedKanbanRow.classList.add("kanban-close");
        clickedKanbanRow.style.display = "none";
        arrowUp.style.display = "none";
        arrowDown.style.display = "block";
      }
    }, */
    addOrganization(org, index) {
      let organization = {
        _id: nanoid(16),
        company_name: org.company_name,
        reg_code: org.reg_code,
        organization: org.company_name,
        organizationSearchingMethod: "Estonian",
      };
      this.$set(this.privatePerson.organizations, index, organization);
    },
    removeOrganization(orgToRemove) {
      this.privatePerson.organizations.forEach((org, index) => {
        if (org.reg_code === orgToRemove.reg_code) {
          let emptyFormOrganizations = {
            _id: nanoid(16),
            organization: null,
            organizationSearchingMethod: "Estonian",
            company_name: null,
          };
          this.$set(
            this.privatePerson.organizations,
            index,
            emptyFormOrganizations,
          );
        }
      });
    },
    addEmptyForm() {
      let emptyForm = {
        _id: nanoid(16),
        email: null,
      };
      this.privatePerson.emails.push(emptyForm);
    },
    addEmptyFormOrganizations() {
      let emptyFormOrganizations = {
        _id: nanoid(16),
        organization: null,
        organizationSearchingMethod:
          this.userCountry === "ee" ? "Estonian" : "Generic",
        address: null,
        phoneNum: null,
        orgEmail: null,
        reg_code: null,
        company_name: null,
      };
      this.privatePerson.organizations.push(emptyFormOrganizations);
    },
    addEmptyFormPhoneNumbers() {
      let emptyFormPhoneNumbers = {
        _id: nanoid(16),
        phoneNumber: null,
      };
      this.privatePerson.phoneNumbers.push(emptyFormPhoneNumbers);
    },
    removeForm(index) {
      let emptyObject = {
        _id: nanoid(16),
        email: null,
      };
      if (index === 0) {
        this.$set(this.privatePerson.emails, index, emptyObject);
      } else {
        this.privatePerson.emails.splice(index, 1);
      }
    },
    removeFromOrganizations(index) {
      let emptyObjectOrganizations = {
        _id: nanoid(16),
        organization: null,
        organizationSearchingMethod:
          this.userCountry === "ee" ? "Estonian" : "Generic",
        address: null,
        phoneNum: null,
        orgEmail: null,
      };
      if (index === 0) {
        this.$set(
          this.privatePerson.organizations,
          index,
          emptyObjectOrganizations,
        );
      } else {
        this.privatePerson.organizations.splice(index, 1);
      }
    },
    removeFormPhoneNumbers(index) {
      let emptyObjectPhoneNumbers = {
        _id: nanoid(16),
        phoneNumber: null,
      };
      if (index === 0) {
        this.$set(
          this.privatePerson.phoneNumbers,
          index,
          emptyObjectPhoneNumbers,
        );
      } else {
        this.privatePerson.phoneNumbers.splice(index, 1);
      }
    },
    async deleteCustomer() {
      const response = await contactApi.deleteContact(
        this.privatePerson.customerId,
      );

      if (response === 0) {
        this.$toast.error(this.$t("ErrorDeleting"));
        return;
      } else {
        this.$toast.success(
          this.$t("Contact") + " " + this.$t("Deleted").toLowerCase(),
        );
      }
      this.closeModal();
      this.$emit("deleted");
    },
    async submitCustomer() {
      if (this.isLoading) return;
      if (this.tab === 0) {
        /*   let regExp = /[a-zA-Z]/g;
        let firstNameString = this.privatePerson.firstName; */
        //let lastNameString = this.privatePerson.lastName;
        let firstNameFilled = false;
        //let lastNameFilled = false;
        if (
          this.privatePerson &&
          this.privatePerson.firstName &&
          this.privatePerson.firstName.replace(/\s/g, "").length
        ) {
          firstNameFilled = true;
        } else {
          this.isFilledFirstName = true;
        }
        /*  if (regExp.test(lastNameString)) {
          lastNameFilled = true;
        } else {
          this.isFilledLastName = true;
        } */
        if (firstNameFilled /* && lastNameFilled */) {
          this.privatePerson.customerName = this.customerName;
          //adding first value to separate entity
          if (this.privatePerson.emails[0].email != null) {
            this.privatePerson.email = this.privatePerson.emails[0].email;
          } else {
            this.privatePerson.email = "";
          }
          if (this.privatePerson.phoneNumbers[0].phoneNumber != null) {
            this.privatePerson.phoneNumber =
              this.privatePerson.phoneNumbers[0].phoneNumber;
          } else {
            this.privatePerson.phoneNumber = "";
          }
          if (this.privatePerson.organizations[0].organization != null) {
            this.privatePerson.organization =
              this.privatePerson.organizations[0].organization;
          } else {
            this.privatePerson.organization = "";
          }

          if (this.noteContent) {
            this.privatePerson.notes.push({
              id: nanoid(8),
              content: this.noteContent,
              linkedTo: this.privatePerson._id,
              parentName: this.privatePerson.customerName,
            });
          }

          this.privatePerson.groupIds = this.selectedItems.map(
            (group) => group.value.value,
          );

          this.isLoading = true;
          // // eslint-disable-next-line no-unreachable
          const customerSaveResponse = await this.addCustomer(
            this.privatePerson,
          );

          if (customerSaveResponse === 0) {
            this.$toast.error(this.$t("ErrorCreating"));
            this.isLoading = false;
            return;
          } else {
            this.$toast.success(
              this.$t("Contact") + " " + this.$t("Created").toLowerCase(),
            );
            this.isLoading = false;
            if (!this.notContactViewRoute) {
              await this.$router.push({
                name: "KliendiregisterDetail",
                params: { id: customerSaveResponse.data },
              });
            }
          }
          this.privatePerson.customerId = customerSaveResponse.data;
          this.$emit("newAdded", customerSaveResponse.data);
          this.setNewAddedContact({
            id: this.privatePerson.customerId,
            name: this.privatePerson.customerName,
          });
          this.$emit(
            "newAddedContact",
            this.privatePerson,
            customerSaveResponse.data,
          );

          if (!this.notContactViewRoute) {
            this.$emit("openClientDetailPanel");
          }
          this.$emit("closed");
        } else {
          if (!this.isFormValid()) {
            this.showErrors = true;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  width: 100%;
  height: 64px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.modal-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  padding-top: 0 !important;
  z-index: 7;
  width: 640px;
  overflow: scroll;
  border: 1px solid #e6e8ec;
}

.v-window {
  overflow: scroll;
}

.modal-header {
  display: flex;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 25px;
  justify-content: space-between;
}

.modal-title {
  font-size: 24px;
}

.form-wrapper {
  padding-top: 24px;
  padding-bottom: 124px;
  padding-left: 32px;
  padding-right: 64px;
}

.input-wrapper {
  gap: 12px;
  justify-content: space-between;
  height: auto;
  width: 100%;
}

.optional-text {
  color: rgb(0, 0, 0);
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
  margin-right: auto;
}

.optional-toggle {
  border: 1px solid rgb(244, 245, 247);
  display: flex;
  outline: none;
  padding: 12px;
  border-radius: 8px;
}

:deep(.v-input--radio-group--row .v-input--radio-group__input) {
  flex-wrap: nowrap;
}

:deep(.v-input--selection-controls) {
  padding-top: 0;
}

:deep(.v-text-field .v-input__control),
.v-text-field .v-input__slot,
.v-text-field fieldset {
  background-color: white;
}

:deep(.v-text-field.v-text-field--enclosed .v-text-field__details) {
  display: none;
}

.clear-row {
  width: 100%;
  justify-content: flex-end;
}

.clear-button {
  display: flex;
  flex-direction: row;
  gap: 2px;
  cursor: pointer;
}

.add-another-row {
  margin-top: 24px;
  margin-left: 16px;
}

.show-more {
  margin-bottom: 16px;
}

.toggle-true {
  transform: rotate(180deg);
}

.no-active {
  width: 100% !important;
  max-height: 52px;
  //padding-right: 56px;
  z-index: 110;
}

.no-active2 {
  width: 100% !important;
  max-height: 52px;
  //padding-right: 56px;
  z-index: 110;
}

:deep(.v-window) {
  overflow: hidden;
}

.add-relation-button {
  border: 1px solid #e6e8ec;
  height: 100%;
  display: flex;
  outline: none;
  padding: 12px 16px;
  font-size: 14px;
  align-items: center;
  font-weight: 400;

  span {
    padding: 12px 16px;
  }
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 100%;
  height: 56px;
  padding: 0 !important;
  padding-bottom: 0 !important;

  &:focus {
    outline: none !important;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 16px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 16px;
}

.tags-selection {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.tag-container {
  display: flex;
  border-right: 1px solid #e6e8ec;
  height: 52px;
  align-items: center;
  cursor: pointer;
  padding-left: 8px;
  justify-content: center;
}

.last-container {
  border-right: none;
}

.tag-abbreviation {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
}

.white-tag-text {
  color: white;
}

.big-font {
  font-weight: 500 !important;
}
.orange-ground {
  color: orangered !important;
}
:deep(.black--text) {
  color: #ff5c01 !important;
  caret-color: #ff5c01 !important;
}

:deep(.theme--light.v-input),
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ff5c01 !important;
}

.overlay-addmodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

:deep(.v-card__actions) {
  padding: 0;
}

.close-button {
  border: none;
  padding: 8px;
  background: #f4f5f7;
  border-radius: 8px;
  height: 32px;
}

.buttons-container {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 640px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  position: fixed;
  background: white;
  height: 97px;
  padding-right: 56px;
  width: 640px;
}

.delete-button {
  background-color: transparent !important;
  box-shadow: 0 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.birthday-picker {
  max-width: 50%;
}

:deep(.v-tab) {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 26px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #000000 !important;
}

:deep(.v-slide-group__content) {
  gap: 24px;
}

:deep(.v-input--selection-controls .v-input__slot > .v-label),
.v-input--selection-controls .v-radio > .v-label {
  color: #000000 !important;
  letter-spacing: 0.05em !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.edit-footer {
  justify-content: space-between !important;
}

.add-another-button {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.tag-name {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding-right: 2.5px;
  padding-left: 2.5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.selected-font {
  background-color: #f4f5f7;
}
.selected-font-first {
  background-color: #f4f5f7;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.selected-font-last {
  background-color: #f4f5f7;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

:deep(.not-valid fieldset) {
  border: 2px solid #ff1e24 !important;
}

:deep(.input-container) {
  background-color: white;
}
</style>
