<template>
  <div v-if="user" class="navbar">
    <div class="ok-wrap">
      <img
        style="border-radius: 30px; cursor: pointer"
        :src="require('@/assets/images/logo.svg')"
        @click="projectPage()"
      />
    </div>

    <v-list class="links-wrap" nav dense>
      <v-list-item
        v-if="showDashboard"
        v-tooltip="$t('Dashboard')"
        link
        :to="{ name: 'DashBoard' }"
        tabindex="0"
      >
        <img
          :src="require('@/assets/images/dashboard.svg')"
          style="padding-top: 5px"
          alt=""
        />
      </v-list-item>

      <div
        class="flex-row separator"
        v-if="showDashboard"
        style="margin-top: 4px; margin-bottom: 8px"
      ></div>

      <v-list-item
        v-if="showProjects"
        v-tooltip="$t('Projects')"
        link
        :to="{ name: 'TheDeals' }"
        tabindex="0"
      >
        <img
          :src="require('@/assets/images/ProjectIcon.svg')"
          style="padding-top: 5px"
          alt=""
        />
      </v-list-item>

      <v-list-item
        v-if="showListings"
        v-tooltip="$t('Listings')"
        link
        :to="{ name: 'TheListings' }"
        tabindex="0"
      >
        <img
          :src="require('@/assets/images/menu/building.svg')"
          style="padding-top: 5px"
          alt=""
        />
      </v-list-item>

      <v-list-item
        v-if="showPropertyDeveloper"
        v-tooltip="$t('DevelopmentProjects')"
        name="navbar-property-developer-button"
        link
        :to="{ name: 'PropertyDevelopment' }"
        tabindex="0"
      >
        <img
          :src="require('@/assets/images/PropertyDeveloper.svg')"
          style="padding-top: 5px"
          alt=""
        />
      </v-list-item>

      <v-list-item
        v-if="showPropertyOwner"
        v-tooltip="$t('Properties')"
        name="navbar-propertyowner-button"
        link
        :to="{ name: 'PropertyOwner' }"
        tabindex="0"
      >
        <img
          :src="require('@/assets/images/PropertyOwner.svg')"
          style="padding-top: 5px"
          alt=""
        />
      </v-list-item>

      <div
        class="flex-row separator"
        v-if="
          !user.config ||
          (user.config && user.access?.features?.modules?.calendar?.enabled)
        "
        style="margin-bottom: 8px; margin-top: 4px"
      ></div>

      <v-list-item
        v-if="showCalendar"
        v-tooltip="$t('Calendar')"
        link
        :to="{ name: 'Calendar' }"
        tabindex="0"
      >
        <img
          :src="require('@/assets/images/menu/calendar.svg')"
          style="padding-top: 5px"
          alt=""
        />
      </v-list-item>

      <v-list-item
        v-if="showContacts"
        v-tooltip="$t('Contacts')"
        name="navbar-contacts-button"
        link
        to="/contacts?page=1"
        tabindex="0"
      >
        <img
          :src="require('@/assets/images/menu/client.svg')"
          style="padding-top: 5px"
          alt=""
        />
      </v-list-item>

      <div
        class="flex-row separator"
        v-if="showTransactions"
        style="margin-bottom: 8px; margin-top: 4px"
      ></div>

      <v-list-item
        v-if="showTransactions"
        v-tooltip="$t('Transactions')"
        link
        to="/transactions?page=1"
        tabindex="0"
      >
        <img
          :src="require('@/assets/images/transactions.svg')"
          style="padding-top: 5px"
          alt=""
        />
      </v-list-item>

      <v-list-item
        v-if="showInvoices"
        v-tooltip="$t('Invoices')"
        link
        to="/invoices/?page=1"
        tabindex="0"
      >
        <img
          :src="require('@/assets/images/invoice.svg')"
          style="padding-top: 5px"
          alt=""
        />
      </v-list-item>

      <!-- <v-list-item v-if="showScorecard" v-tooltip="$t('Scorecard')" name="navbar-scorecard-button" link :to="{ name: 'Scorecard' }" tabindex="0">
        <img :src="require('@/assets/images/scorecard.svg')" style="padding-top: 5px;" alt=""/>
      </v-list-item> -->

      <div
        class="flex-row separator"
        v-if="
          showSettings &&
          (!user.config ||
            (user.config && user.access?.features?.modules?.invoices?.enabled))
        "
        style="margin-top: 4px; margin-bottom: 8px"
      ></div>

      <v-list-item
        v-if="showSettings"
        v-tooltip="$t('Admin')"
        name="navbar-admins-button"
        link
        :to="{ name: 'Admin' }"
        tabindex="0"
      >
        <img
          :src="require('@/assets/images/menu/admin.svg')"
          style="padding-top: 5px"
          alt=""
        />
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavBar",

  data: () => ({}),
  mounted() {},
  computed: {
    ...mapGetters(["user"]),
    active() {
      return true;
    },
    showDashboard() {
      let disabledCompanies = ["_dw_1O8jj0QTYIRahjbF"];
      if (
        this.user.config &&
        !this.user.access?.features?.modules?.dashboard?.enabled
      ) {
        return false;
      }
      if (disabledCompanies.includes(this.user.companyId)) {
        return false;
      }
      return true;
    },
    showProjects() {
      let disabledCompanies = ["7QxJBWjXZYrUUBq5OuZo", "_dw_1O8jj0QTYIRahjbF"];
      if (
        this.user.config &&
        !this.user.access?.features?.modules?.projects?.enabled
      ) {
        return false;
      }
      if (disabledCompanies.includes(this.user.companyId)) {
        return false;
      }
      return true;
    },
    showTransactions() {
      let disabledCompanies = ["7QxJBWjXZYrUUBq5OuZo", "_dw_1O8jj0QTYIRahjbF"];
      if (
        this.user.config &&
        !this.user.access?.features?.modules?.transactions?.enabled
      ) {
        return false;
      }
      if (disabledCompanies.includes(this.user.companyId)) {
        return false;
      }
      return true;
    },
    showInvoices() {
      let disabledCompanies = ["7QxJBWjXZYrUUBq5OuZo", "_dw_1O8jj0QTYIRahjbF"];
      if (
        this.user.config &&
        !this.user.access?.features?.modules?.invoices?.enabled
      ) {
        return false;
      }
      if (disabledCompanies.includes(this.user.companyId)) {
        return false;
      }
      return true;
    },
    showCalendar() {
      if (
        this.user.config &&
        !this.user.access?.features?.modules?.calendar?.enabled
      ) {
        return false;
      }
      return true;
    },
    showListings() {
      let disabledCompanies = ["_dw_1O8jj0QTYIRahjbF"];
      if (disabledCompanies.includes(this.user.companyId)) {
        return false;
      }
      return true;
    },
    showContacts() {
      return true;
    },
    showSettings() {
      return (
        this.user.access.accessLevel === "company_admin" ||
        this.user.access.accessLevel === "group_admin"
      );
    },
    showScorecard() {
      let enabledCompanies = [
        "UNhloeIRQy9J19caCGXU",
        "wcgQphbhIZdITwYe2VIZ",
        "5p1EOo4yqjeiYCN3R16w",
        "yAuL6x3yKIacfejp0MYl",
      ];
      if (enabledCompanies.includes(this.user.companyId)) {
        return true;
      }
      return false;
    },
    showPropertyOwner() {
      let enabledCompanies = [
        "sTtxCiAQFhBSUWlb53__",
        "pMlBRbc8fIHcfE4P2hIz",
        "7QxJBWjXZYrUUBq5OuZo",
        "_dw_1O8jj0QTYIRahjbF",
        "vU_FgUHWcfNiEjBGy6bz",
      ];
      if (
        (this.user.isAdmin &&
          !this.user.config &&
          this.user.companyId === "yAuL6x3yKIacfejp0MYl") ||
        enabledCompanies.includes(this.user.companyId)
      ) {
        return true;
      }
      return false;
    },
    showPropertyDeveloper() {
      let enabledCompanies = [
        "sTtxCiAQFhBSUWlb53__",
        "pMlBRbc8fIHcfE4P2hIz",
        "vU_FgUHWcfNiEjBGy6bz",
        "Nh-UVddKpP82a8gglcus",
        "d4awXn68uHcnmKO8cJax",
        "5p1EOo4yqjeiYCN3R16w",
        "0Kum6B4gJpw8WmlHTbvB",
        "XgueSGJcSiOjBSk_Sqnz",
        "JzuCm7o_scgsbbbqPfYu",
        "2STeziIYJsENkW5As9LQ",
        "SPSJGI2EHB4qiWDgbH9F",
        "3UKuGiqNzcwEH8ZvfPgG",
        "zYdkwF7AXv8eicJdDsQx",
        "MNZQVHzhsWfzHKXvshL0",
        "oSwq8a1X1r9k4zQVDH-E",
        "YvQ5kL6oWP3P1F8tglur",
        "wNeu-3EudSwfkOtBHOZu",
        "bm7DM4QdrnLppKsMGgmY",
        "H9k9xcvU04W7Ymsh5SDD",
        "9l-DjKBHeWQxSvNGG7MV",
        "1HviP3w7wquaiCxGDZ1_",
        "xmwei_Rt9ryfpkxL4BL8",
      ];
      let enabledUsers = [
        "63fcdd8c42639d100ee7c492",
        "62b047ed1324246fe1c6fddd",
        "61fbf086a8519ada0a01cae4",
        "632b415d1ec9deb58a1980c0",
        "61fc0607a8519ada0a01d0c6",
        "64245690c7091ddabc71501b",
        "6418862598eab2726c7d8661",
        "6269299bba02d13fcc1276fd",
        "63fe4ef426a0ce8471269387",
        "633ed455991bd949509fd13f",
        "643fe6bb1c813d21e34bca6e",
        "6418862598eab2726c7d8661",
        "6432f847e76e41b7b5ea206a",
        "64297f907f58556aaf78c862",
        "64297f6a7f58556aaf78c751",
        "643c10d25e6d1388fa98c324",
        "6432f840e76e41b7b5ea204a",
        "642c2a97f003b870bb6ffff7",
        "643c10db5e6d1388fa98c354",
        "64297f7c7f58556aaf78c7db",
        "64297f747f58556aaf78c7a2",
        "6404bea20840174311e802d8",
        "6404be9f0840174311e802c8",
        "6404be8f0840174311e80268",
        "64297f777f58556aaf78c7b5",
        "64297f937f58556aaf78c875",
        "63fcdda942639d100ee7c529",
        "64297f7f7f58556aaf78c7f0",
        "64297f877f58556aaf78c829",
        "64270d70fa627388e01e3627",
        "6555f33c2f7bea8162f1d6dc",
      ];
      if (
        this.user.config &&
        this.user.access?.features?.modules?.developments?.enabled
      ) {
        return true;
      }

      if (
        (this.user.isAdmin &&
          !this.user.config &&
          this.user.companyId === "yAuL6x3yKIacfejp0MYl") ||
        enabledCompanies.includes(this.user.companyId) ||
        enabledUsers.includes(this.user._id)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    projectPage() {
      if (this.user.companyId === "_dw_1O8jj0QTYIRahjbF") {
        this.$router.push("/property-owner");
      } else if (!this.user.config) {
        this.$router.push("/projects");
      } else {
        this.$router.push("/listings");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 40;
  background-color: white;
  width: 64px !important;
  position: fixed;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  height: 100vh;
}

.ok-wrap {
  display: flex;
  justify-content: center;
  //margin-top: 32px;
}

.links-wrap {
  margin-top: 68px !important;
  margin: 0;
  display: flex;
  padding: 0;
  align-items: center;
  flex-direction: column;
  list-style-type: none;
}

.v-list-item {
  width: 48px;
  min-height: 48px !important;
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: center;

  &:before {
    border-radius: 8px;
  }
}

.separator {
  height: 1px;
  width: 48px;
  background: #000000;
}

/* .v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 8px !important;
} */

/* .v-list--nav.v-list--dense .v-list-item:not(:first-child):not(:only-child) {
  margin-top: 8px !important;
} */
</style>
