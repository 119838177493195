<template>
  <div class="notifications">
    <div @click="toggleMenu" class="notifications-button">
      <img :src="require('@/assets/images/bell.svg')" alt="" />
      <div v-if="newNotificationsCounter" class="unread-counter">
        <p class="label10">{{ newNotificationsCounter }}</p>
      </div>
    </div>

    <div v-click-outside="closeMenu" v-if="open" class="notifications-menu">
      <div class="notifications-wrapper">
        <div v-if="!notifications.length" class="empty-notifications">
          <p class="content-semibold">{{ $t("NotificationsListIsEmpty") }}</p>
          <p class="content-small">
            {{ $t("YourNotificationsWillAppearHere") }}
          </p>
        </div>
        <div v-if="notifications.length">
          <div
            @click="readNotification(notification, index)"
            v-for="(notification, index) in notifications"
            :key="notification._id"
            class="notification"
          >
            <div
              v-bind:class="{
                'notification-status visited': notification.visited,
                'notification-status not-visited': !notification.visited,
              }"
            ></div>
            <div class="notification-content">
              <p
                v-html="formatNotificationMessage(notification)"
                class="content"
              ></p>
              <p class="content-small notification-date grey900">
                {{ formatDate(notification.createdAt) }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        @click="clearNotifications"
        v-if="notifications.length"
        class="clear-notifications"
      >
        <p class="content">{{ $t("ClearAll") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import listingApi from "@/http/listing";

export default {
  name: "TheNotifications",
  data() {
    return {
      notifications: null,
      open: false,
    };
  },
  sockets: {
    connect: function () {
      console.log(100);
    },
  },
  computed: {
    ...mapGetters(["user"]),
    newNotificationsCounter() {
      let count = 0;
      if (this.notifications) {
        for (const notification of this.notifications) {
          if (!notification.viewed) {
            count++;
          }
        }
      }

      return count;
    },
  },
  created() {
    if (this.user) {
      this.getNotifications();
      this.setupSocket();
    }
  },
  methods: {
    ...mapActions(["getListings"]),
    async getNotifications() {
      const response = await axios.get("/api/notifications");
      this.notifications = response.data;
    },
    setupSocket() {
      if (this.$socket && this.user && this.user._id) {
        this.$socket.emit("subscribe", this.user._id);
        this.$socket.on("notification", () => {
          this.getNotifications();
        });
      } else {
        console.error(
          "Socket object or user id is not defined",
          this.$socket,
          this.user,
        );
      }
    },
    async toggleMenu() {
      this.open = !this.open;
      if (this.open) {
        await axios.post("/api/notifications/viewed");
        for (let notification of this.notifications) {
          notification.viewed = true;
        }
      }
    },
    closeMenu() {
      this.open = false;
    },
    async readNotification(notification, index) {
      await this.goToNotification(notification);

      if (!notification.visited) {
        const response = await axios.post(
          `/api/notification/visited/${notification._id}`,
        );
        if (response.status === 200) {
          this.notifications[index].visited = true;
        }
      }
    },
    async clearNotifications() {
      const response = await axios.post("/api/notifications/clear");
      if (response.status === 200) {
        this.notifications = [];
      }
    },
    async goToNotification(notification) {
      const type = notification.action.object.type;
      const objectId = notification.action.object._id;

      let page = await listingApi.getListingsPage(objectId);
      let listingStatus = "listings";
      if (page.status === "not found") return;
      if (page.listingStatus === "archived") {
        listingStatus = "archives";
      }
      if (type === "listing") {
        await this.$router
          .push({
            name: "TheListings",
            query: { notificationId: 0, listingStatus: listingStatus },
          })
          .catch(() => {});

        await this.$router
          .push({
            name: "TheListings",
            query: {
              notificationId: objectId,
              page: page.page,
              listingStatus: listingStatus,
            },
          })
          .catch(() => {});
      } else if (type === "event") {
        await this.$router
          .push({
            name: "Calendar",
            query: { notificationId: null },
          })
          .catch(() => {});
        await this.$router
          .push({
            name: "Calendar",
            query: {
              notificationId: objectId,
              type: "event",
            },
          })
          .catch(() => {});
        this.closeMenu();
      } else if (type === "task") {
        await this.$router
          .push({
            name: "Calendar",
            query: { notificationId: null },
          })
          .catch(() => {});

        await this.$router
          .push({
            name: "Calendar",
            query: { notificationId: objectId, type: "task" },
          })
          .catch(() => {});
        this.closeMenu();
      } else if (type === "scorecard") {
        await this.$router
          .push({
            name: "TheScorecard",
          })
          .catch(() => {});
      }
      if (type === "transaction") {
        await this.$router
          .push({
            name: "TheTransactions",
            query: { notificationId: null },
          })
          .catch(() => {});

        await this.$router
          .push({
            name: "TheTransactions",
            query: { notificationId: objectId, type: "transaction" },
          })
          .catch(() => {});
        this.closeMenu();
      }
    },

    formatNotificationMessage(notification) {
      const initiator = notification.initiator.name;
      const action = notification.action.name;
      const objectName = notification.action.object?.name;
      let message;
      switch (action) {
        case "transfer_ownership":
          message = initiator + " " + this.$t("assignedListingToYou");
          break;
        case "listing_expired":
          message =
            this.$t("Listing") +
            " " +
            `<em>
            ${objectName}
            </em>` +
            " " +
            this.$t("IsExpired");
          break;
        case "listing_booking_expired":
          message =
            this.$t("Listing") +
            " " +
            `<em>
              ${objectName}
              </em>` +
            this.$t("booking") +
            " " +
            this.$t("IsExpired");
          break;
        case "listing_booking_expires_tomorrow":
          message =
            this.$t("Listing") +
            " " +
            `<em>
                ${objectName}
                </em>` +
            this.$t("booking") +
            " " +
            this.$t("WillExpireTomorrow");
          break;
        case "listing_expiry_warning":
          message =
            this.$t("Listing") +
            " " +
            `<em>
            ${objectName}
            </em>` +
            " " +
            this.$t("WillExpireInThreeDays");
          break;
        case "assign_task":
          message = initiator + " " + this.$t("assignedTaskToYou");
          break;
        case "task_due":
          message =
            this.$t("TaskIsDue.Task") +
            " " +
            `<em>
            ${objectName}
            </em>` +
            " " +
            this.$t("TaskIsDue.isDue");
          break;
        case "event_invitation":
          message =
            initiator +
            " " +
            this.$t("InvitedToEvent") +
            " " +
            `<em>
              ${objectName}
              </em>`;
          break;
        case "event_due":
          message =
            this.$t("EventIsDue.Event") +
            " " +
            `<em>
            ${objectName}
            </em>` +
            " " +
            this.$t("EventIsDue.isDue");
          break;
        case "interested_offer":
          message =
            initiator +
            " " +
            this.$t("isInterestedInListing") +
            " " +
            `<em>
            ${objectName}
            </em>`;
          break;
        case "rejected_offer":
          message =
            initiator +
            " " +
            this.$t("isNotInterestedInListing") +
            " " +
            `<em>
            ${objectName}
            </em>`;
          break;
        case "transaction_lease_agreement_expiry_30days":
          message =
            `<em>
            ${objectName}
            </em>` +
            this.$t("LeaseAgreement") +
            " " +
            this.$t("WillExpireIn").toLowerCase() +
            " " +
            moment(notification.action.object.endDate).format("DD.MM.YYYY");
          break;
        case "transaction_lease_agreement_expired":
          message =
            `<em>
            ${objectName}
            </em>` +
            this.$t("LeaseAgreement") +
            " " +
            this.$t("IsExpired");
          break;
        case "scorecard":
          message = this.$t(notification.action.message);
          break;
      }
      return message;
    },
    formatDate(e) {
      e = e / 1000;
      let date = moment.unix(e);
      let end = moment();

      const hoursAgo = end.diff(date, "hours");
      if (hoursAgo > 0 && hoursAgo <= 24) {
        if (hoursAgo === 1) {
          return hoursAgo + " " + this.$t("hourAgo");
        } else {
          return hoursAgo + " " + this.$t("hoursAgo");
        }
      }
      if (hoursAgo > 24) {
        const daysAgo = end.diff(date, "days");
        if (daysAgo === 1) {
          return daysAgo + " " + this.$t("dayAgo");
        } else {
          return daysAgo + " " + this.$t("daysAgo");
        }
      } else {
        const minutesAgo = end.diff(date, "minutes");
        if (minutesAgo === 1) {
          return minutesAgo + " " + this.$t("minuteAgo");
        } else {
          return minutesAgo + " " + this.$t("minutesAgo");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../global_variables";

.notifications-button {
  height: 24px;
  cursor: pointer;
}

.notifications-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: absolute;
  margin-top: 12px;
  margin-left: -140px;
  max-width: 280px;
  width: 280px;
  max-height: 300px;
  overflow: hidden;
  z-index: 4;
  background: #e6e8ec;
  /* shadow1 */

  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.notifications-wrapper {
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.notification {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  width: 100%;
  background: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 0 1px 0;

  &:hover {
    cursor: pointer;
    background-color: $light-grey;
  }
}

.empty-notifications {
  display: flex;
  height: 96px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  width: 100%;
  background: #ffffff;
}

.notification-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 12px 0px 0px;
}

.visited {
  background: #e6e8ec;
}

.not-visited {
  background: #ff5c01;
}

.notification-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 4px;
}

.unread-counter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: -36px;
  margin-left: 12px;
  background: #ff1e24;
  min-width: 16px;
  min-height: 16px;
  padding: 2px;
  border-radius: 4px;
}

.clear-notifications {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  width: 100%;
  height: 52px;
  background: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 1px 0px;
  cursor: pointer;
}
</style>
