<template>
  <div @keydown="searchItem" :id="id" class="input-field-container">
    <div
      v-if="fieldType !== 'date'"
      ref="container"
      :key="fieldUpdater"
      :name="'dropdown-' + id"
      @click="focusInput"
      class="form-field-large"
      :class="{ disabled: fieldDisabled }"
      :style="fieldStyle"
    >
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        style="display: block"
        v-if="iconLeft"
        :src="require(`@/assets/images/` + iconLeft)"
        alt=""
      />
      <div
        :class="{ required: fieldRequired }"
        style="display: flex; width: 100%; flex-direction: column"
      >
        <label
          v-if="fieldPlaceholder"
          ref="label"
          :style="labelStyle"
          class="content"
          >{{ fieldPlaceholder }}</label
        >
        <input
          @focus="focusInputElementPlaceholder"
          @keyup.enter="focusInputElement"
          :type="fieldType"
          :disabled="fieldDisabled"
          @focusout="unfocusInput"
          ref="input"
          id="input-element"
          class="input-field content"
          v-model="fieldValue"
        />
      </div>
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        v-if="fieldDropdownArrow"
        :src="require(`@/assets/images/dropdown_20_20.svg`)"
        alt=""
      />
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        v-if="iconRight"
        :src="require(`@/assets/images/` + iconRight)"
        alt=""
      />
    </div>
    <div
      v-if="fieldType === 'date'"
      @focus-within="focusInputElementPlaceholder"
      @focusout="unfocusInput"
      :class="{
        focused: fieldValue || isDatePickerFocused,
        'error-border': dateError,
      }"
    >
      <VueDatePicker
        v-model="fieldValue"
        @update:model-value="updateDate"
        :locale="currentLocale"
        :enable-time-picker="false"
        :format="format"
        :cancel-text="$t('Cancel')"
        :select-text="$t('Select')"
        @focus="handleFocus"
        @blur="handleBlur"
        style="width: auto; border-radius: 12px"
      >
        <template #dp-input="{ value }">
          <div
            style="border-radius: 12px"
            @click="toggle"
            :class="{ disabled: fieldDisabled }"
            ref="container"
            :key="fieldUpdater"
            :name="'dropdown-' + id"
            :style="fieldStyle"
          >
            <img
              :class="{ 'icon-disabled': fieldDisabled }"
              style="display: block"
              v-if="iconLeft"
              :src="require(`@/assets/images/` + iconLeft)"
              alt=""
            />
            <div
              :class="{ required: fieldRequired }"
              style="
                display: flex;
                width: 100%;
                flex-direction: column;
                height: 36px;
                justify-content: space-between;
              "
            >
              <label ref="label" :style="dateLabelStyles" class="content">{{
                fieldPlaceholder
              }}</label>
              <div>{{ value }}</div>
            </div>
          </div>
        </template>
      </VueDatePicker>
      <div v-if="dateError" class="content error-message">{{ dateError }}</div>
    </div>
    <div
      ref="dropdown"
      v-click-outside="closeDropdown"
      v-if="fieldDropdownOpen || (fieldDropdownOpen && fieldDropdownAddNew)"
      class="dropdown-container"
      id="container-dropdown"
      :style="fieldDropdownStyle"
      style="left: 12px; top: 4px; right: 12px"
    >
      <div
        tabindex="0"
        @keyup.enter="selectDropdownitem(null)"
        v-if="
          fieldValue &&
          fieldValue.length > 3 &&
          fieldDropdownAllowNull &&
          !required
        "
        @click="selectDropdownitem(null)"
        class="content dropdown-item"
      >
        {{ $t("none") }}
      </div>
      <div
        style="position: relative; overflow-y: auto; max-height: 200px"
        :id="id + 'dropdown-parent-container'"
        :class="{
          'dropdown-medium': fieldDropdownAllowNull,
          'dropdown-small': fieldDropdownAllowNull && fieldDropdownAddNew,
        }"
      >
        <div
          tabindex="0"
          @keyup.enter="selectDropdownitem(item)"
          @click="selectDropdownitem(item)"
          :id="id + '-dropdown-item-' + index"
          :name="id + '-dropdown-item'"
          v-for="(item, index) in usableItems"
          :key="item.value ? item.value : index"
          class="content dropdown-item"
          v-bind:class="{
            'content dropdown-item': !item.hidden,
            hidden: item.hidden,
          }"
          style="display: flex; flex-wrap: nowrap"
        >
          {{ item.name ? item.name : item.text }}
        </div>
      </div>
      <div
        v-if="fieldDropdownAddNew"
        @click="dropdownAddNewButton"
        style="display: flex; gap: 8px; justify-content: center"
        class="dropdown-item"
        :class="{ bordert: fieldDropdownItems.length > 0 }"
      >
        <img :src="require(`@/assets/images/action-add_16_16.svg`)" />
        <p class="content">{{ $t("AddNew") }}</p>
      </div>
    </div>
    <div
      v-if="fieldError"
      class="content error-message"
      style="margin: 12px 12px 0px 0px"
    >
      {{
        fieldErrorMessage ? fieldErrorMessage : $t("FormErrors.FieldRequired")
      }}
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import axios from "axios";
import VueDatePicker from "@vuepic/vue-datepicker";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { ref } from "vue";

export default {
  name: "BaseDropdown",
  props: [
    "type",
    "modelValue",
    "id",
    "placeholder",
    "required",
    "size",
    "iconLeft",
    "iconRight",
    "errorMessage",
    "error",
    "disabled",
    "showDropdownArrow",
    "items",
    "returnObject",
    "dropdownOffsetY",
    "dropdownAddNew",
    "dropdownAllowNull",
    "dropdownZIndex",
    "startDate",
    "endDate",
  ],
  emits: ["update:modelValue"],
  components: { VueDatePicker },
  setup() {
    const { locale: i18nLocale } = useI18n();

    const currentLocale = ref(i18nLocale.value);

    const format = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return currentLocale.value === "et" || currentLocale.value === "ee"
        ? `${day}.${month}.${year}`
        : `${day}/${month}/${year}`;
    };

    return {
      format,
      currentLocale,
    };
  },
  data() {
    return {
      fieldType: "dropdown",
      fieldValue: this.modelValue,
      fieldPlaceholder: "",
      fieldRequired: false,
      fieldSize: "large",
      fieldClass: "form-field-large",
      fieldError: false,
      fieldErrorMessage: false,
      fieldDisabled: false,
      originalArr: null,
      usableItems: [],
      updateDropdownItems: 0,
      fieldStyle: {
        border: "1px solid #E6E8EC",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        padding: "7px 11px",
        gap: "12px",
        background: "white",
        cursor: "text",
      },
      labelStyle: {
        color: "#75787A",
      },
      selectedItemValue: "",
      fieldUpdater: 0,
      selectedFieldDropdownItemValue: null,
      fieldDropdownArrow: false,
      fieldDropdownItems: null,
      fieldDropdownOpen: false,
      fieldDropdownStyle: {
        left: "12px",
        top: "4px",
        right: "12px",
      },
      fieldDropdownAddNew: false,
      fieldDropdownAllowNull: true,
      fieldOrganizationsFound: [],
      fieldOrganizationsFoundClone: [],
      search: "",
      isFocused: false,
      preventBlur: false,
      selectableYearRange: {
        from: moment().subtract(110, "years").year(),
        to: moment().add(10, "years").year(),
      },
      dateError: null,
    };
  },
  watch: {
    fieldValue(value) {
      this.searchValue(value);

      if (
        this.fieldType === "dropdown" &&
        this.selectedFieldDropdownItemValue !== null
      ) {
        this.$emit("update:modelValue", this.selectedFieldDropdownItemValue);
        return;
      }
      if (
        this.fieldType === "dropdown" &&
        !this.selectedFieldDropdownItemValue
      ) {
        this.$emit("update:modelValue", this.fieldValue);
        return;
      }
      if (this.fieldType === "date") {
        this.validateDate();
        this.$emit("update:modelValue", this.fieldValue);
        return;
      }
      if (this.fieldType === "organizationsearch") {
        if (this.selectedFieldDropdownItemValue) {
          let organization = {
            _id: nanoid(16),
            company_name: this.selectedFieldDropdownItemValue.company_name,
            reg_code: this.selectedFieldDropdownItemValue.reg_code,
            organization: this.selectedFieldDropdownItemValue.company_name,
            organizationSearchingMethod: "Estonian",
          };
          this.$emit("update:modelValue", organization);
          this.selectedFieldDropdownItemValue = null;
          return;
        }
        if (
          this.selectedItemValue &&
          this.selectedItemValue.length > value.length
        ) {
          this.$emit("update:modelValue", null);
        }
        if (!this.selectedFieldDropdownItemValue && this.fieldValue === "") {
          this.$emit("update:modelValue", null);
        }
        if (this.modelValue.company_name === value) {
          return;
        }
        if (this.fieldValue !== "" && this.fieldType === "organizationsearch") {
          this.organizationSearch(value);
        }
      }
    },
    errorMessage() {
      this.fieldError = true;
      this.fieldErrorMessage = this.errorMessage;
    },
    error() {
      this.checkError();
    },
    disabled() {
      this.fieldDisabled = this.disabled;
    },
    startDate(newStartDate) {
      this.validateDate(newStartDate, this.endDate);
    },
    endDate(newEndDate) {
      this.validateDate(this.startDate, newEndDate);
    },
  },
  mounted() {
    this.initialize();
    this.fieldDisabled = this.disabled;
    this.searchValue(this.fieldValue);
    this.checkError();
  },
  computed: {
    dateLabelStyles() {
      return {
        color: this.isFocused ? "#FF5C01" : "#75787A",
        fontSize: this.fieldValue ? "11px" : "14px",
        lineHeight: this.fieldValue ? "12px !important" : "20px",
        transition: "0.2s",
        cursor: "pointer",
      };
    },
  },
  methods: {
    clearFieldValue() {
      this.fieldValue = "";
      this.isFocused = false;
      this.handleOutsideClick();
    },
    validateDate() {
      const startDate = this.startDate;
      const endDate = this.endDate;

      if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
        this.dateError = this.$t("EndDateEarlier");
        this.fieldStyle.border = "2px solid #FF1E24";
      } else {
        this.dateError = null;
        this.fieldStyle.border = "1px solid #E6E8EC";
      }
    },
    handleClick(event) {
      let targetElement = event.target;
      do {
        if (targetElement.tagName === "TD") {
          this.isFocused = false;
          return;
        }
        if (
          targetElement.classList &&
          targetElement.classList.contains("vdpOuterWrap")
        ) {
          return;
        }
        if (
          targetElement.tagName === "SELECT" ||
          targetElement.tagName === "BUTTON"
        ) {
          this.preventBlur = true;
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.isFocused = !this.isFocused;
    },
    handleOutsideClick() {
      this.preventBlur = false;
      this.isFocused = false;
    },
    handleDocumentClick(event) {
      const datePickElement = this.$refs.datePick
        ? this.$refs.datePick.$el
        : null;
      let targetElement = event.target;
      do {
        if (
          targetElement === datePickElement ||
          (targetElement.classList &&
            targetElement.classList.contains("vdpOuterWrap"))
        ) {
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.preventBlur = false;
    },
    handleBlur() {
      this.$nextTick(() => {
        if (this.preventBlur) {
          this.preventBlur = false;
          return;
        }
        this.isFocused = false;
      });
    },
    formatDate(date) {
      if (typeof date === "string") {
        return date;
      }
      const parsedDate = moment(date);
      if (!parsedDate.isValid()) {
        return "";
      }
      return parsedDate.format("DD.MM.YYYY");
    },
    checkError() {
      this.fieldError = this.error;
      if (this.fieldError) {
        this.fieldStyle.border = "2px solid #FF1E24";
        this.fieldStyle.padding = "6px 10px";
        this.$nextTick(() => {
          if (this.$refs.label) {
            this.$refs.label.style.color = "#FF1E24";
          }
        });
      } else {
        this.fieldStyle.border = "1px solid #E6E8EC";
        this.fieldStyle.padding = "7px 11px";
        this.$nextTick(() => {
          if (this.$refs.label) {
            this.$refs.label.style.color = "";
          }
        });
        if (this.validateInput(this.fieldValue)) {
          this.$nextTick(() => {
            if (this.$refs.label && this.isFocused) {
              this.$refs.label.style.color = "#FF5C01";
            } else if (this.$refs.label && !this.isFocused) {
              this.$refs.label.style.color = "#75787A";
            }
          });
        }
      }
    },
    async initialize() {
      await this.mapProps();
    },
    searchItem(event) {
      if (!this.fieldDropdownOpen) return;

      if (event.key === "Backspace") {
        this.search = this.search.slice(0, -1);
      }

      let tempSearch = this.search + event.key;
      let itemIndex = null;
      let searchWord = tempSearch.toLowerCase();
      let searchKey = "name";
      if (this.usableItems && this.usableItems.length) {
        this.usableItems.forEach((item, index) => {
          if (item[searchKey].toLowerCase().startsWith(searchWord)) {
            itemIndex = index;
          }
        });
      }

      if (itemIndex == null) return;

      this.search = this.search + event.key;

      let parentElement = document.getElementById(
        this.id + "dropdown-parent-container",
      );
      let childElement = document.getElementById(
        this.id + "-dropdown-item-" + itemIndex,
      );
      parentElement.scrollTop =
        childElement.offsetTop - parentElement.offsetTop;
      childElement.focus();
    },
    searchValue() {
      if (this.type !== "date" && this.fieldType !== "organizationsearch") {
        this.originalArr = [...(this.fieldDropdownItems || [])];
        const inputElement = document.getElementById("input-element");
        if (inputElement) {
          inputElement.blur();
        }
        this.usableItems = this.originalArr;
      }
    },
    mapProps() {
      if (this.dropdownOffsetY) {
        this.fieldDropdownStyle.top = `${this.dropdownOffsetY}px`;
      }
      if (this.dropdownZIndex) {
        this.fieldDropdownStyle.zIndex = this.dropdownZIndex;
      }
      if (this.type) {
        const propType = this.type.toLowerCase();
        if (this.required) {
          this.fieldRequired = true;
        }
        if (propType === "date") {
          this.fieldType = propType;
          this.initializeDateSearch();
          return;
        }
        if (propType === "organizationsearch") {
          this.selectedItemValue = this.modelValue.organization;
          this.fieldType = propType;
          this.initializeOrganizationSearch();
          return;
        }
      }
      this.initializeDropdown();
    },
    validateInput(value) {
      return !(value === "" || /^\s*$/.test(value));
    },
    openDropdown(focus = true) {
      if (this.usableItems && this.usableItems.length >= 10) {
        this.$refs.input.focus();
      }
      if (
        !this.validateInput(this.fieldValue) &&
        this.fieldType === "organizationsearch"
      ) {
        this.$nextTick(() => (this.$refs.input.style.height = "24px"));
        this.activatePlaceholder();
        this.$nextTick(() => this.$refs.input.focus());
        this.fieldOrganizationsFound = [];
        this.fieldDropdownItems = [];
      }
      this.fieldDropdownOpen = true;
      if (focus) {
        this.$nextTick(() => this.$refs.dropdown.focus());
      }
    },
    closeDropdown() {
      this.fieldDropdownOpen = false;
    },
    focusInputElementPlaceholder() {
      this.isFocused = true;
      this.activatePlaceholder();
      this.$refs.input.style.transition = "0.2s";
      this.$refs.input.style.height = "24px";
      if (this.fieldType === "date") {
        this.$nextTick(() => this.$refs.input.showPicker());
      }
    },
    focusInput() {
      this.isFocused = true;
      this.$refs.label.style.transition = "0.2s";
      if (this.fieldDisabled) {
        return;
      }
      switch (this.fieldType) {
        case "dropdown":
          this.openDropdown();
          break;
        case "organizationsearch":
          this.openDropdown(false);
          this.activatePlaceholder();
          break;
        case "date":
          this.activatePlaceholder();
          this.$refs.input.style.height = "24px";
          this.$refs.input.focus();
          break;
      }
    },
    focusInputElement() {
      this.isFocused = true;
      if (this.fieldType === "organizationsearch") {
        this.focusInput();
        return;
      }
      this.fieldDropdownOpen = true;
    },
    async unfocusInput() {
      // if (!this.validateInput(this.fieldValue)) {
      //   this.$refs.input.style.height = "0px";
      //   this.deactivatePlaceholder();
      //   if (this.fieldType === "organizationsearch") {
      //     this.closeDropdown();
      //   }
      // }
      this.isFocused = false;
      this.fieldFocus = false;
      this.labelStyle.color = "#75787A";
    },
    activatePlaceholder(fieldActive = true) {
      this.$nextTick(() =>
        this.$refs.label?.classList.add("form-field-large-active-placeholder"),
      );
      if (this.fieldError) {
        this.labelStyle.color = "#FF1E24";
        return;
      }
      if (fieldActive) {
        this.labelStyle.color = "#FF5C01";
        return;
      }
      if (!fieldActive) {
        this.labelStyle.color = "#75787A";
      }
      this.$nextTick(() => this.$refs.label?.classList.add("required-mark"));
    },
    deactivatePlaceholder() {
      this.$refs.label?.classList.remove("form-field-large-active-placeholder");
      this.labelStyle.color = "#75787A";
      this.$refs.label?.classList.remove("required-mark");
    },
    initializeOrganizationSearch() {
      if (this.dropdownAddNew) {
        this.fieldDropdownAddNew = true;
      }
      if (this.dropdownAllowNull === false) {
        this.fieldDropdownAllowNull = false;
      }
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
      if (this.modelValue) {
        if (this.modelValue.organization) {
          this.activatePlaceholder(false);
          this.fieldValue = this.modelValue.organization;
        } else {
          this.$nextTick(() => (this.$refs.input.style.height = "0px"));
        }
      }
    },
    async organizationSearch(value) {
      if (
        value.length === 3 ||
        ((this.fieldOrganizationsFound.length === 0 ||
          this.fieldOrganizationsFound.length === 1) &&
          value.length > 2)
      ) {
        const response = await axios.get(`/api/fetch-organizations/${value}`);
        const foundItemsApi = response.data;
        if (foundItemsApi.length > 0) {
          this.fieldOrganizationsFound = [];
          foundItemsApi.forEach((el) => {
            if (/^\d+$/.test(el.reg_code)) {
              this.fieldOrganizationsFound.push(el);
            }
          });
        }
        this.fieldOrganizationsFoundClone = this.fieldOrganizationsFound;
      }
      if (value.length > 3) {
        this.fieldOrganizationsFound = [];
        this.fieldOrganizationsFoundClone.forEach((organization) => {
          if (
            organization.company_name
              .toLowerCase()
              .includes(value.toLowerCase())
          ) {
            this.fieldOrganizationsFound.push(organization);
          }
        });
      }
      if (this.fieldType === "organizationsearch") {
        this.usableItems = [];
        this.fieldOrganizationsFound.forEach((org) => {
          this.usableItems.push({
            name: org.company_name,
            value: org.reg_code,
          });
        });
        return;
      }

      this.fieldDropdownItems = [];
      this.fieldOrganizationsFound.forEach((org) => {
        this.fieldDropdownItems.push({
          name: org.company_name,
          value: org.reg_code,
        });
      });
      this.openDropdown(false);
    },
    selectDropdownitem(item) {
      if (!item && this.fieldType === "organizationsearch") {
        this.fieldValue = "";
        this.selectedFieldDropdownItemValue = null;
        return;
      }
      if (item && this.fieldType === "organizationsearch") {
        this.selectedItemValue = item.name;
        this.fieldValue = item.name;
        this.selectedFieldDropdownItemValue = {
          company_name: item.name,
          reg_code: item.value,
        };
        this.closeDropdown();
        return;
      }
      this.$emit("itemSelected");
      if (item === null || item.value === null) {
        this.fieldValue = "";
        this.selectedFieldDropdownItemValue = null;
        if (this.$refs.input) {
          this.$refs.input.style.height = "0px";
        }
        this.deactivatePlaceholder();
        this.closeDropdown();
        this.$emit("update:modelValue", null);
        return;
      }
      this.fieldValue = item.name ? item.name : item.text;
      if (this.returnObject) {
        this.selectedFieldDropdownItemValue = {
          value: item.value,
          name: item.name ? item.name : item.text,
        };
      } else {
        this.selectedFieldDropdownItemValue = item.value;
      }
      if (this.$refs.input) {
        this.$refs.input.style.height = "24px";
      }
      this.activatePlaceholder(false);
      this.closeDropdown();
    },
    initializeDropdown() {
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
      if (this.items) {
        this.fieldDropdownItems = this.items;
      }
      if (this.dropdownAllowNull === false) {
        this.fieldDropdownAllowNull = false;
      }
      if (this.dropdownAddNew) {
        this.fieldDropdownAddNew = true;
      }
      if (this.modelValue) {
        if (this.modelValue.value) {
          this.fieldValue = this.fieldDropdownItems.find(
            (item) => item.value === this.modelValue.value,
          );
        } else {
          this.fieldValue = this.fieldDropdownItems.find(
            (item) => item.value === this.modelValue,
          );
        }
        if (this.fieldValue) {
          this.activatePlaceholder(false);
          this.selectedFieldDropdownItemValue = this.fieldValue.value;

          if (this.returnObject) {
            this.selectedFieldDropdownItemValue = {
              value: this.fieldValue.value,
              name: this.fieldValue.name,
            };
          }
          this.fieldValue = this.fieldValue.name
            ? this.fieldValue.name
            : this.fieldValue.text;
        } else {
          this.$nextTick(() => {
            if (this.$refs.input) {
              this.$refs.input.style.height = "0px";
            }
          });
        }
      } else {
        this.$nextTick(() => {
          if (this.$refs.input) {
            this.$refs.input.style.height = "0px";
          }
        });
      }
      if (this.showDropdownArrow) {
        this.fieldDropdownArrow = true;
      }
      this.$nextTick(() => {
        if (this.$refs.label) {
          this.$refs.label.style.cursor = "pointer";
        }
        if (this.$refs.input) {
          this.$refs.input.style.cursor = "pointer";
        }
      });
      this.fieldStyle.cursor = "pointer";
    },
    initializeDateSearch() {
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
      if (this.modelValue) {
        this.fieldValue = this.modelValue;
        this.activatePlaceholder(false);
      }
      if (this.showDropdownArrow) {
        this.fieldDropdownArrow = true;
      }
      if (this.fieldValue) {
        this.$nextTick(() => {
          if (this.$refs.input) {
            this.$refs.input.style.height = "24px";
          }
        });
      } else {
        this.$nextTick(() => {
          if (this.$refs.input) {
            this.$refs.input.style.height = "0px";
          }
        });
      }
      this.$nextTick(() => {
        if (this.$refs.input) {
          this.$refs.input.style.marginLeft = "-1px";
          this.$refs.input.max = "9999-12-31";
          this.$refs.input.style.cursor = "pointer";
        }
        if (this.$refs.label) {
          this.$refs.label.style.cursor = "pointer";
        }
      });
      this.fieldStyle.cursor = "pointer";
    },
    toggle() {
      if (this.fieldDisabled) return;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.date-picker-value {
  margin: 2px 0 0 0;
}

.disabled {
  background: $grey !important;
}

.vdpHeadCellContent {
  font-size: 1.2em !important;
}

.date-picker-content {
  display: flex;
  align-items: center;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  padding: 7px 11px;
  background: white;
  cursor: pointer;
  width: 100%;
  height: 52px;
  display: flex;
  gap: 12px;
}

.date-field {
  display: flex;
  align-items: flex-end;
}

.dp__pointer {
  height: 52px !important;
  border-radius: 8px;
}

.input-field-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.form-field-large {
  height: 52px;
  width: 100%;
}

.form-field-large label {
  color: #75787a;
  cursor: text;
}

.input-field {
  width: 100%;
  height: 24px;
  outline: none;
  cursor: text;
}

.form-field-large-active-placeholder {
  height: 12px !important;
  line-height: 12px !important;
  font-size: 11px !important;
}

.dropdown-container {
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 1px 10px 1px rgba(0, 0, 0, 0.08),
    0px 3px 10px -2px rgba(0, 0, 0, 0.03);
  z-index: 110;
  border-radius: 8px;
  position: absolute;
  max-height: 300px;
  overflow-y: hidden;
  overflow-x: hidden;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.hidden {
  display: none !important;
}
.dropdown-medium {
  max-height: 256px !important;
}

.dropdown-small {
  max-height: 212px !important;
}

:deep(.dp__input) {
  height: 52px !important;
  width: 140px;
  border-radius: 8px !important;
}

.dropdown-item {
  padding: 12px;
  background: white;
  cursor: pointer;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04); */
}

.dropdown-item:focus {
  outline: 0;
  background-color: #f4f5f7;
}

.dropdown-item:hover {
  background: #f4f5f7;
}

.form-field-large input[type="date"]:after {
  content: "";
}

.form-field-large input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
}

.form-field-large input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 48px;
  color: transparent;
  background: transparent;
  cursor: pointer;
}
.error-message {
  color: #ff1e24 !important;
}
.required label::after {
  position: absolute !important;
  line-height: unset !important;
}

.required-mark::after {
  font-size: 12px !important;
  line-height: unset !important;
  position: absolute !important;
}
.required label::after,
.required h6::after {
  content: "*";
  color: #ff1e24;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.error-message {
  color: red;
  margin-top: 4px;
}
</style>
