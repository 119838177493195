<template>
  <div style="width: 100%; z-index: 99">
    <div
      @click="focusInput"
      @focusout="unfocusInput"
      :id="'popover' + randomId"
      :class="'content-wrapper ' + (showError && !object ? 'not-valid' : '')"
      style="width: 100%; height: 52px !important"
    >
      <span :id="'InAddressRow' + randomId" style="width: 100%"></span>
    </div>

    <div
      class="flex-row error-text"
      v-if="showError && !object"
      style="margin-top: 10px; font-size: 14px; font-family: Inter"
    >
      {{ $t("FormErrors." + errorMessage) }}
    </div>
  </div>
</template>
<script>
import { nanoid } from "nanoid";
import axios from "axios";
export default {
  name: "AddressFinder",
  props: {
    placeholder: String,
    object: {
      type: [Object, String],
      default: () => {
        return {};
      },
    },
    showError: Boolean,
    errorMessage: String,
    disabled: Boolean,
    defaultAddress: String,
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    randomId: nanoid(10),
    addressSearchContainer: null,
    labelElement: null,
    inputElement: null,
    dropdownContainer: null,
    clearButton: null,
    aadressSearch: null,
    fieldPlaceholder: "",
  }),
  created() {},
  mounted() {
    this.initiateApi();
    this.$emit("loaded");
  },
  beforeUnmount() {
    this.aadressSearch = null;
  },
  watch: {
    watch: {
      object() {
        if (
          this.object &&
          typeof this.object === "object" &&
          this.object.address
        ) {
          const findInput = setInterval(() => {
            if (this.aadressSearch) {
              this.aadressSearch.setAddress(this.object.address);

              if (this.inputElement) {
                this.activatePlaceholder(true);
                this.focusInput();
                clearInterval(findInput);
              }
            } else {
              console.error("aadressSearch is not initialized yet.");
            }
          }, 100);
        }
      },
    },
    showError(value) {
      if (value) {
        this.addressSearchContainer.style.border = "2px solid rgb(255, 30, 36)";
        this.addressSearchContainer.style.padding = "6px 10px";
      } else {
        this.addressSearchContainer.style.border =
          "1px solid rgb(230, 232, 236)";
        this.addressSearchContainer.style.padding = "7px 11px";
      }
    },
  },
  computed: {},
  methods: {
    focusInput() {
      this.activatePlaceholder();
    },
    async unfocusInput() {
      this.deactivatePlaceholder();
    },
    activatePlaceholder() {
      this.labelElement.style.fontSize = "11px";
      this.labelElement.style.height = "12px";
      this.labelElement.style.color = "#FF5C01";
      this.labelElement.style.lineHeight = "11px";
      //input
      this.inputElement.style.height = "24px";
      this.inputElement.focus();
      document.addEventListener("click", this.checkIfDropdownClicked);
    },
    deactivatePlaceholder() {
      this.labelElement.style.color = "#75787a";
      if ((!this.object || !this.object.address) && !this.inputElement.value) {
        this.setupInput();
        this.setupLabel();
      }
    },
    checkIfDropdownClicked(e) {
      if (this.isDropdownOpen()) {
        const paths = e.path || (e.composedPath && e.composedPath());
        paths.forEach((path) => {
          if (path.classList && path.classList.contains("inads-result")) {
            // not hidden
          } else {
            this.dropdownContainer.classList.add("hidden");
          }
        });
      }
    },
    isDropdownOpen() {
      return !this.dropdownContainer.classList.contains("hidden");
    },
    containsWord(str, word) {
      return str.match(new RegExp("\\b" + word + "\\b")) != null;
    },
    setupAddressSearchContainer() {
      this.addressSearchContainer.style.display = "flex";
      this.addressSearchContainer.style.flexDirection = "column";
      this.addressSearchContainer.style.justifyContent = "center";
    },
    setupLabel() {
      if (!this.addressSearchContainer) {
        console.error("Address search container not found");
        return;
      }

      if (!this.labelElement) {
        this.labelElement = document.createElement("label");
        this.labelElement.style.fontSize = "14px";
        this.labelElement.classList.add("label-item");
        this.labelElement.style.transition = "0.2s";
        this.labelElement.style.color = "#75787a";
        this.labelElement.style.width = "calc(100% - 36px)";
        this.labelElement.style.transform = "translateY(-4px)";
        this.labelElement.innerHTML = this.placeholder;
      }
      if (!this.addressSearchContainer.contains(this.labelElement)) {
        this.addressSearchContainer.insertBefore(
          this.labelElement,
          this.addressSearchContainer.firstChild,
        );
      }
    },
    setupInput() {
      if (!this.inputElement) {
        console.error("Input element not found");
        return;
      }
      this.inputElement.style.height = "24px";
      this.inputElement.style.transition = "0.2s";
      this.inputElement.style.backgroundColor = "";
      this.inputElement.style.width = "calc(100% - 36px)";
      this.inputElement.value = this.defaultAddress || "";

      this.labelElement.style.fontSize = "11px";
      this.labelElement.style.height = "12px";
      this.labelElement.style.lineHeight = "11px";
      this.labelElement.style.color = "#75787a";
    },
    setupDropdownContainer() {
      this.dropdownContainer.style.top = "0";
      this.dropdownContainer.style.width = "472px";
      this.dropdownContainer.style.margin = "0 auto";
      this.dropdownContainer.style.border = "none";
      this.dropdownContainer.style.boxShadow =
        "0 4px 8px rgb(0 0 0 / 4%), 0 1px 2px rgb(0 0 0 / 3%), 0 0 1px rgb(0 0 0 / 4%)";
      this.dropdownContainer.style.borderRadius = "8px";
    },
    setupClearButton() {
      this.clearButton.addEventListener("click", (e) => this.removeObject(e));
    },
    initiateApi() {
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
      // eslint-disable-next-line no-undef
      this.aadressSearch = new InAadress({
        container: "InAddressRow" + this.randomId,
        mode: "3",
        nocss: true,
        appartment: 0,
        ihist: 0,
        lang: "et",
        namespace: "addressFinder",
      });

      /*  const search = setInterval(() => { */
      this.addressSearchContainer =
        document.getElementsByClassName("inads-input-div")[0];
      if (this.addressSearchContainer) {
        //clearInterval(search);
        //this.labelElement = document.createElement("label");
        this.isRequired
          ? this.addressSearchContainer.classList.add("required")
          : "";
        this.inputElement = document.getElementsByClassName("inads-input")[0];
        this.dropdownContainer =
          document.getElementsByClassName("inads-result")[0];
        this.clearButton =
          document.getElementsByClassName("inads-input-clear")[0];
        this.setupLabel();
        this.setupClearButton();
        this.setupAddressSearchContainer();
        this.setupInput();
        this.setupDropdownContainer();
      }
      /*   }, 20); */

      document.addEventListener(
        "addressSelected.addressFinder",
        async (a) => await this.addressSelected(a),
      );
    },
    async addressSelected(a) {
      if (!a.detail) return;
      this.$emit("objectSetting");
      console.log(a.detail);
      let address = a.detail[0];
      let newObject = { _id: nanoid(15) };
      newObject.cadastralNumber = null;
      newObject.ehrNumber = null;
      a.detail.forEach((param) => {
        if (param.liik === "CU") {
          newObject.cadastralNumber = param?.orig_tunnus;
        } else {
          newObject.ehrNumber = param?.orig_tunnus;
        }
      });
      newObject.street = address.liikluspind;
      newObject.houseNumber = address.aadress_nr;
      newObject.county = address.maakond;
      newObject.address = address.paadress;
      newObject.estateNumber = null;
      if (newObject.cadastralNumber) {
        const response = await axios.get(
          `https://geoportaal.maaamet.ee/url/xgis-ky.php?ky=${newObject.cadastralNumber}&out=json`,
          {
            withCredentials: false,
          },
        );
        if (response && response.data && response.data[1]) {
          if (response.data[1].Registriosa) {
            newObject.estateNumber = response.data[1].Registriosa;
          } else {
            newObject.estateNumber = null;
          }
          newObject.landArea = null;
          if (response.data[1].Pindala) {
            let area = response.data[1].Pindala;
            if (area.includes("m²")) {
              let number = response.data[1].Pindala.replace(/m²/g, "");
              newObject.landArea = {
                m2: number.toString(),
                m2Selected: true,
                ha: null,
                haSelected: false,
              };
              //newObject.landArea = number;
            } else if (area.includes("ha")) {
              let haArea = response.data[1].Pindala.replace(/ha/g, "");
              let number = haArea * 10000;
              newObject.landArea = {
                m2: number.toString(),
                m2Selected: true,
                ha: null,
                haSelected: false,
              };
              //newObject.landArea = number;
            }
          }
        }
      }

      let asum = address.asum;
      let asustusyksus = address.asustusyksus;
      let kohanimi = address.nimi;
      if (kohanimi.length > 1) {
        newObject.street = kohanimi;
      }
      if (
        !this.containsWord(asustusyksus, "linn") &&
        !this.containsWord(asum, "linn")
      ) {
        if (asum.length > 1 && asum === "Vanalinna asum") {
          newObject.district = asum;
        }
        /* else {
          newObject.cityPart = null;
          newObject.district = asustusyksus;
        }*/
      }
      //if (this.containsWord(asustusyksus, "linn")) {
      //newObject.city = asustusyksus;
      //newObject.city = asustusyksus.split(" linn")[0];
      newObject.district = asustusyksus;
      //newObject.cityPart = null;
      //}

      //if (this.containsWord(address.omavalitsus, " linn")) {
      //newObject.city = address.omavalitsus.split(" linn")[0];
      newObject.city = address.omavalitsus;
      // } else {
      //  newObject.city = address.omavalitsus;
      // }

      newObject.units = [];
      newObject.listings = [];
      newObject.coordinates = {
        lat: 59.437109,
        lng: 24.743749,
      };
      newObject.coordinates.lat = a.detail[0].b;
      newObject.coordinates.lng = a.detail[0].l;
      if (!a.detail[0].ehak) {
        newObject.ehakCode = a.detail[0].ehakov;
      } else {
        newObject.ehakCode = a.detail[0].ehak;
      }
      try {
        this.aadressSearch.hideResult();
        // eslint-disable-next-line no-empty
      } catch (e) {}

      const removeElem = document.querySelector('[title="puhasta"]');
      removeElem.style.display = "block";
      this.$emit("setObject", newObject);
    },
    createObjectAddress(object) {
      let listingTitleObject = {
        street: object.street,
        houseNumber: object.houseNumber,
        district: object.district,
        cityPart: object.cityPart,
        city: object.city,
        county: object.county,
      };

      listingTitleObject = Object.fromEntries(
        Object.entries(listingTitleObject).filter(
          //eslint-disable-next-line no-unused-vars
          ([_, v]) => v != null && v != "" && v != "undefined",
        ),
      );
      let listingTitle = "";
      for (const [key, value] of Object.entries(listingTitleObject)) {
        if (key && value && value !== "undefined") {
          if (key === "street" && listingTitleObject.houseNumber) {
            listingTitle = listingTitle + value + " ";
          } else {
            listingTitle = listingTitle + value + ", ";
          }
        }
      }

      let titleEnd = listingTitle.slice(listingTitle.length - 2);
      if (titleEnd === ", ") {
        listingTitle = listingTitle.slice(0, -2);
      }
      return listingTitle;
    },
    closePopover(e) {
      let popover = document.getElementById("overlay");
      if (e.target === popover) {
        this.showPopUp(0);
      }
    },
    removeObject(e) {
      e.stopPropagation();
      this.aadressSearch.setAddress("");
      this.activatePlaceholder();
      this.$emit("removeObject");
    },
  },
};
</script>
<style lang="scss" scoped>
.readonly {
  pointer-events: none;
}

.content-wrapper {
  display: flex;
  align-items: flex-start;
}

:deep(#InAadressDiv) {
  z-index: 5;
}

:deep(.inads-input-div) {
  border: 1px solid rgb(230, 232, 236);
  height: 52px !important;
  border-radius: 8px;
  cursor: text;
  padding: 7px 11px;
  display: flex;
  margin-top: 0;
}

:deep(.inads-input-div label) {
  font-family: Inter, sans-serif !important;
  line-height: 20px;
  cursor: text;
}

:deep(.inads-input) {
  border: none;
  height: 0;
  padding: 0 !important;
  position: unset !important;
}

:deep(.inads-copyright) {
  display: none !important;
}

:deep(.inads-input-search) {
  display: none;
}

:deep(.inads_spinner) {
  display: none;
}

:deep(.inads-input-clear) {
  position: absolute;
  top: 14px;
  right: 10px;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/close_grey.svg");
  background-size: 24px 24px;
}

:deep(.inads-result li:first-child) {
  border-radius: 8px 8px 0 0;
}

:deep(.inads-result li:only-child) {
  border-radius: 8px;
}

:deep(.inads-result li:last-child) {
  border-radius: 0 0 8px 8px;
}

:deep(.inads-result li:hover) {
  background-color: #f4f5f7;
  color: black !important;
}

:deep(.inads-result li) {
  padding: 7px 11px;
}

:deep(.inads-result li span) {
  font-family: Inter, sans-serif !important;
  font-size: 14px;
}
</style>
