<template>
  <div style="display: flex">
    <img
      @click="toggleMenu"
      :src="require('@/assets/images/question_mark.svg')"
      alt=""
      style="cursor: pointer"
    />
    <div class="info-menu" v-if="dropdownOpen" v-click-outside="closeMenu">
      <div
        v-for="(item, index) in menuItems"
        :key="'menu-item-' + index"
        class="menu-item"
        @click="() => selectItem(item.name)"
      >
        <img
          :src="require(`../../assets/images/${item.iconUrl}`)"
          alt=""
          class="img16"
        />

        <div class="content-small">
          {{ item.field }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TheInfo",
  components: {},
  props: [],
  computed: {
    ...mapGetters(["language"]),
    menuItems() {
      let guideItems =
        this.language === "et"
          ? [
              {
                name: "guide-et",
                iconUrl: "info.svg",
                field: `${this.$t("Guide")} (PDF)`,
              },
            ]
          : [
              {
                name: "guide-en",
                iconUrl: "info.svg",
                field: `${this.$t("Guide")} (PDF)`,
              },
            ];
      return [
        ...guideItems,
        {
          name: "version-history",
          iconUrl: "menu/megaphone.svg",
          field: `${this.$t("VersionHistory")} (PDF)`,
        },
        {
          name: "email",
          iconUrl: "email.svg",
          field: "support@runproperty.com",
        },
        { name: "phone", iconUrl: "call.svg", field: "+372 6333633" },
      ];
    },
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions([]),
    toggleMenu() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeMenu() {
      this.dropdownOpen = false;
    },
    selectItem(itemName) {
      switch (itemName) {
        case "guide-en":
          window.open(
            "https://drive.google.com/file/d/1x3GFbrHQFnmySopN6Vt0e8gnwRO3bHPp/view",
            "popup",
          );
          break;
        case "guide-et":
          window.open(
            "https://drive.google.com/file/d/1Lu68KrHcYWw8le1IrxK8_oyE7f7_KfR_/view",
            "popup",
          );
          break;
        case "version-history":
          window.open(
            "https://drive.google.com/file/d/1s3kwKNcJ2wOkhhMtn_aw55VEjwYrDVTs/view",
            "popup",
          );
          break;
        case "email":
          window.open(`mailto:support@runproperty.com`, "popup");
          break;
        case "phone":
          window.open("tel:+3726333633", "popup");
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.menu-item {
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: 4px 0;
}

.info-menu {
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex-wrap: nowrap;
  position: absolute;
  margin-top: 30px;
  margin-left: -60px;
  max-height: 300px;
  overflow: hidden;
  z-index: 20;
  padding: 8px 12px;
  background-color: white;

  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
</style>
